import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  MessageBox,
  useTitle,
  MotionDiv,
  CustomTable,
  ViewButton,
  DeleteButton,
} from "../../components";
import { getError, toastOptions } from "../../utils/error";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../features/loadingSlice";
import axiosInstance from "../../utils/axiosUtil";
import { Card, Col, InputGroup, Row, Spinner } from "react-bootstrap";
import CountCard from "../../components/layout/CountCard";
import { AddButton, EditButton } from "../../components/layout/CustomTable";
import SearchField from "../../components/layout/SearchField";
import CreateBtn from "../../components/layout/CreateBtn";
import { MdDashboard } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { selectAuth } from "../../features/authSlice";
import AddTopicSubtopicModal from "../../components/modals/AddTopicSubtopicModal";
import { useCreateDomainMutation, useDeleteDomainByIdMutation, useGetDomainByIdMutation, useGetDomainsMutation, useUpdateDomainByIdMutation } from "../../features/apiSlice";
import Skeleton from 'react-loading-skeleton'
import { fetchDomains } from "../../utils/apis";
import Title from "../../components/layout/Title";
import BackBtn from "../../components/layout/BackBtn";

export default function ListOfDomains() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  // const [users,setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [query, setQuery] = useState("");
 const [domains,setDomains] = useState([]);
 const [id,setId] = useState(null);
  const [isEdit,setIsEdit] = useState(false);
  const [showAddModal,setShowModal] = useState(false);
  const handleShowAddModal = ()=>setShowModal(true)
  const handleHideAddModal = ()=>{
  
  setShowModal(false)
  setId(null);
  setTitle('')
  setDescription('')
}

  //--domain by id
  const [createDomain,{ isLoading:createDomainLoading}] = useCreateDomainMutation();
  const [getDomains,{isLoading}] = useGetDomainsMutation();
  const [getDomainById] = useGetDomainByIdMutation();
  const [updateDomainById,{isLoading:updateDomainLoading}] = useUpdateDomainByIdMutation();
  const [deleteDomainById,{isLoading:deleteLoading}] = useDeleteDomainByIdMutation();

  const [title,setTitle] = useState('')
  const [description,setDescription] = useState('')
  // const domainsTest = [
  //   { domain_name: "Engineering" },
  //   { domain_name: "Medicine" },
  //   { domain_name: "B-Pharm" },
  //   { domain_name: "MBBS" },
  //   { domain_name: "BSC" },
  // ];

  const handleDeleteDomain = async()=>{
    try {
      const data = await deleteDomainById(id).unwrap();
      toast.success(data?.message);
      handleHideAddModal();
      fetchDomains({getDomains,setDomains,params:`key=${query}`});

    } catch (error) {
      getError(error)
    }
  }

  const submitHandler = async(e)=>{
    e.preventDefault();
    try {
      const data = id?
      await updateDomainById({id:id,data:{
        domain_name: title,
        description: description
      }}).unwrap()
      :  await createDomain({
        domain_name: title,
        description: description
      }).unwrap();
      console.log(data);
      toast.success(data?.message);
       handleHideAddModal();
       fetchDomains({getDomains,setDomains,params:`key=${query}`});
      } catch (error) {
      getError(error);
    }
  }

  

  const fetchDomainById = async(id)=>{
   
    setId(id);
    try {
      const data = await getDomainById(id).unwrap();
      console.log(data);
      setTitle(data?.domain?.domain_name);
      setDescription(data?.domain?.description);
      handleShowAddModal(); 
    } catch (error) {
      getError(error);
    }
  }

useEffect(()=>{
  fetchDomains({getDomains,setDomains,params:`key=${query}`});
},[query])
  

  

  // useTitle("Users");
  // useTitle("User Details");
  return (
    <MotionDiv>
<Row>
        <Col className='px-1'>
        
       <BackBtn/>
        </Col>
       </Row>        <>
          <Row className="border-bottom py-2 ">
            <Col  className=" px-1 d-flex align-items-end justify-content-between ">
              {/* <p className="fs-4">List Of Domains</p> */}
            <Title>List Of Domains</Title>
            <div className='d-flex flex-row'>
              <SearchField placeholder="Search Domain" query={query} setQuery={setQuery}/>
            
              {/* <AddButton url="/admin/domains/add-domain" title={"New Domain"} /> */}
              <AddButton func={handleShowAddModal} title={"Add Domain"} />
              </div>
            </Col>
          </Row>

         
            <Row md={3} sm={2} className="my-3 ">
              {isLoading ?
            [...Array(6)].map((_, index) => (
                <Col >
                <Skeleton height={'2.5rem'} className='mb-2' key={index} />
                </Col>
              ))
        :
             domains.length>0 ? domains?.map((data, i) => (
                <Col className="p-1">
                  <Card className="p-2 w-100 ">
                      <Row >
                        <Col className="d-flex justify-content-between">
                        <div style={{maxWidth:'90%'}} className="d-flex">
                        <MdDashboard className="mt-1"/>
                        {data?.domain_name}
                       
                     </div>
                     <div>
                     {getDomainById && id === data?._id?
 <Spinner size="sm"/>
 :
                        <FiEdit
                          className="ms-left "
                          color="rgba(0, 0, 139, 1)"
                          size={20}
                          style={{cursor:'pointer'}}
                          onClick={()=>fetchDomainById(data?._id)}
                        />
                     }
                   </div>
                        </Col>
                      </Row>
                       
                  
                  </Card>
                </Col>
              ))
            :
            <p>No data</p>
            
            }
            </Row>
          
        </>
      

<AddTopicSubtopicModal
 heading={'Domain'} 
  title={title} 
  setTitle={setTitle}
   description={description}
    setDescription={setDescription}
     show={showAddModal}
      onHide={handleHideAddModal} 
  onSubmit={submitHandler} 
  loading={createDomainLoading || updateDomainLoading}
  deleteBtn={id}
  // onSubmit={handleHideAddModal}
  onDeleteClick={handleDeleteDomain}
  deleteLoading={deleteLoading}
  />

    </MotionDiv>
  );
}
