import { BarChart } from '@mui/x-charts'
import React from 'react'

function BarGraph({data,color}) {
  return (
    <BarChart
    sx={{ marginLeft: '-40px', }}
    dataset={data}
    xAxis={[{ scaleType: 'band', dataKey: 'month'}
        

    ]}
     
    series={[
        {dataKey:'count',color: color || 'var(--primary-color)'},
        
    ]}
    // xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
    
    // width={350}
    borderRadius={5}
    height={200}
  />
  )
}

export default BarGraph