
export const SearchIconGrey = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 13L9 9M10.3333 5.66667C10.3333 6.2795 10.2126 6.88634 9.97811 7.45252C9.74358 8.01871 9.39984 8.53316 8.9665 8.9665C8.53316 9.39984 8.01871 9.74358 7.45252 9.97811C6.88634 10.2126 6.2795 10.3333 5.66667 10.3333C5.05383 10.3333 4.447 10.2126 3.88081 9.97811C3.31462 9.74358 2.80018 9.39984 2.36683 8.9665C1.93349 8.53316 1.58975 8.01871 1.35523 7.45252C1.12071 6.88634 1 6.2795 1 5.66667C1 4.42899 1.49167 3.242 2.36683 2.36683C3.242 1.49167 4.42899 1 5.66667 1C6.90434 1 8.09133 1.49167 8.9665 2.36683C9.84167 3.242 10.3333 4.42899 10.3333 5.66667Z" stroke="#A3A3A3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const SearchIconBlue = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5 16.5L11.5 11.5M13.1667 7.33333C13.1667 8.09938 13.0158 8.85792 12.7226 9.56565C12.4295 10.2734 11.9998 10.9164 11.4581 11.4581C10.9164 11.9998 10.2734 12.4295 9.56565 12.7226C8.85792 13.0158 8.09938 13.1667 7.33333 13.1667C6.56729 13.1667 5.80875 13.0158 5.10101 12.7226C4.39328 12.4295 3.75022 11.9998 3.20854 11.4581C2.66687 10.9164 2.23719 10.2734 1.94404 9.56565C1.65088 8.85792 1.5 8.09938 1.5 7.33333C1.5 5.78624 2.11458 4.30251 3.20854 3.20854C4.30251 2.11458 5.78624 1.5 7.33333 1.5C8.88043 1.5 10.3642 2.11458 11.4581 3.20854C12.5521 4.30251 13.1667 5.78624 13.1667 7.33333Z" stroke="#444CE7" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const PlusIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#00008B" />
            <path d="M10 5L10 15M15 10L5 10" stroke="white" strokeWidth="1.66667" strokeLinecap="round" />
        </svg>
    )
}

export const EditIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.36484 2.23461H3.36484C2.03936 2.23461 0.964844 3.30913 0.964844 4.63461V12.6347C0.964844 13.9602 2.03936 15.0347 3.36484 15.0347H11.3648C12.6903 15.0347 13.7648 13.9602 13.7648 12.6347L13.7648 8.63465M4.96484 11.0346L7.8755 10.4481C8.03002 10.417 8.1719 10.3409 8.28332 10.2294L14.7991 3.71007C15.1115 3.3975 15.1113 2.89084 14.7986 2.57853L13.4184 1.19981C13.1058 0.887637 12.5994 0.887849 12.2871 1.20029L5.77069 7.72032C5.65948 7.8316 5.58354 7.97318 5.55238 8.12738L4.96484 11.0346Z" stroke="#475467" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const EyeIcon = () => {
    return (
        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.60156 6.01934C8.60156 6.87919 7.88522 7.57624 7.00156 7.57624C6.11791 7.57624 5.40156 6.87919 5.40156 6.01934C5.40156 5.15948 6.11791 4.46243 7.00156 4.46243C7.88522 4.46243 8.60156 5.15948 8.60156 6.01934Z" stroke="#525252" />
        </svg>
    )
}

export const DeleteIcon = () => {
    return (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.800781 4.04961L15.2008 4.04961M11.6008 16.6496H4.40078C3.40667 16.6496 2.60078 15.8437 2.60078 14.8496V4.94961C2.60078 4.45255 3.00372 4.04961 3.50078 4.04961H12.5008C12.9978 4.04961 13.4008 4.45255 13.4008 4.94961V14.8496C13.4008 15.8437 12.5949 16.6496 11.6008 16.6496ZM6.20078 4.04961H9.80078C10.2978 4.04961 10.7008 3.64667 10.7008 3.14961V2.24961C10.7008 1.75255 10.2978 1.34961 9.80078 1.34961H6.20078C5.70372 1.34961 5.30078 1.75255 5.30078 2.24961V3.14961C5.30078 3.64667 5.70372 4.04961 6.20078 4.04961Z" stroke="#F95428" strokeLinecap="round" />
        </svg>
    )
}

export const AnswerIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2288_42377)">
                <path d="M12 0C5.38293 0 0 5.38293 0 12C0 18.6171 5.38293 24 12 24C18.6171 24 24 18.6171 24 12C24 5.38293 18.6171 0 12 0Z" fill="#EEF4FF" />
                <g clip-path="url(#clip1_2288_42377)">
                    <path d="M16.5 6H7.5C6.67275 6 6 6.67275 6 7.5V14.25C6 15.0773 6.67275 15.75 7.5 15.75H11.625V17.625C11.625 17.7765 11.7161 17.9134 11.8564 17.9715C11.9029 17.9906 11.9516 18 12 18C12.0975 18 12.1935 17.9618 12.2651 17.8901L14.4052 15.75H16.5C17.3273 15.75 18 15.0773 18 14.25V7.5C18 6.67275 17.3273 6 16.5 6ZM17.25 14.25C17.25 14.6636 16.9136 15 16.5 15H14.25C14.1503 15 14.055 15.0394 13.9849 15.1099L12.375 16.7198V15.375C12.375 15.168 12.2074 15 12 15H7.5C7.08637 15 6.75 14.6636 6.75 14.25V7.5C6.75 7.08637 7.08637 6.75 7.5 6.75H16.5C16.9136 6.75 17.25 7.08637 17.25 7.5V14.25Z" fill="var(--primary-color)" />
                    <path d="M12.3412 8.46788C12.2797 8.33513 12.1469 8.25 12.0007 8.25C11.8544 8.25 11.7217 8.33513 11.6602 8.46788L9.41016 13.3429L10.0912 13.6571L10.8565 11.9992H13.1452L13.9102 13.6571L14.5912 13.3429L12.3412 8.46788ZM11.2027 11.2492L12.0007 9.51975L12.7987 11.2492H11.2027Z" fill="var(--primary-color)" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_2288_42377">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
                <clipPath id="clip1_2288_42377">
                    <rect width="12" height="12" fill="white" transform="translate(6 6)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const QuestionIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2288_42917)">
                <path d="M12 0C5.38293 0 0 5.38293 0 12C0 18.6171 5.38293 24 12 24C18.6171 24 24 18.6171 24 12C24 5.38293 18.6171 0 12 0Z" fill="#EEF4FF" />
                <path d="M12.9999 17.9999C12.9999 18.5522 12.5522 18.9999 11.9999 18.9999C11.4477 18.9999 11 18.5522 11 17.9999C11 17.4477 11.4477 17 11.9999 17C12.5522 17 12.9999 17.4477 12.9999 17.9999Z" fill="var(--primary-color)" />
                <path d="M13.5829 12.642C13.229 12.805 12.9999 13.162 12.9999 13.5511V14.0001C12.9999 14.552 12.553 15.0001 12 15.0001C11.447 15.0001 11.0001 14.552 11.0001 14.0001V13.5511C11.0001 12.384 11.686 11.3141 12.7451 10.8251C13.764 10.3561 14.4999 9.11102 14.4999 8.5C14.4999 7.12213 13.379 6.00006 12 6.00006C10.621 6.00006 9.50006 7.12213 9.50006 8.5C9.50006 9.05206 9.05292 9.50012 8.49994 9.50012C7.94696 9.50012 7.5 9.05206 7.5 8.5C7.5 6.0191 9.51892 4 12 4C14.4811 4 16.5 6.0191 16.5 8.5C16.5 9.85114 15.3279 11.8371 13.5829 12.642Z" fill="var(--primary-color)" />
            </g>
            <defs>
                <clipPath id="clip0_2288_42917">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const ExtraOptionsIcon = () => {
    return (
        <svg width="6" height="17" viewBox="0 0 6 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.99922 4.8998C2.00511 4.8998 1.19922 4.09392 1.19922 3.0998C1.19922 2.10569 2.00511 1.2998 2.99922 1.2998C3.99333 1.2998 4.79922 2.10569 4.79922 3.0998C4.79922 4.09392 3.99333 4.8998 2.99922 4.8998Z" stroke="#2D2C2C" />
            <path d="M2.99922 10.2998C2.00511 10.2998 1.19922 9.49392 1.19922 8.4998C1.19922 7.50569 2.00511 6.6998 2.99922 6.6998C3.99333 6.6998 4.79922 7.50569 4.79922 8.4998C4.79922 9.49392 3.99333 10.2998 2.99922 10.2998Z" stroke="#2D2C2C" />
            <path d="M2.99922 15.6998C2.00511 15.6998 1.19922 14.8939 1.19922 13.8998C1.19922 12.9057 2.00511 12.0998 2.99922 12.0998C3.99333 12.0998 4.79922 12.9057 4.79922 13.8998C4.79922 14.8939 3.99333 15.6998 2.99922 15.6998Z" stroke="#2D2C2C" />
        </svg>
    )
}

export const CrossCancelIcon = () => {
    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5013 0.666992H13.5013C15.3423 0.666992 16.8346 2.15938 16.8346 4.00033V14.0003C16.8346 15.8413 15.3423 17.3337 13.5013 17.3337H3.5013C1.66035 17.3337 0.167969 15.8413 0.167969 14.0003V4.00033C0.167969 2.15938 1.66035 0.666992 3.5013 0.666992ZM14.9744 15.4735C15.3651 15.0828 15.5846 14.5529 15.5846 14.0003V4.00033C15.5846 3.44779 15.3651 2.91789 14.9744 2.52719C14.5837 2.13649 14.0538 1.91699 13.5013 1.91699H3.5013C2.35071 1.91699 1.41797 2.84973 1.41797 4.00033V14.0003C1.41797 14.5529 1.63746 15.0828 2.02816 15.4735C2.41886 15.8642 2.94877 16.0837 3.5013 16.0837H13.5013C14.0538 16.0837 14.5837 15.8642 14.9744 15.4735Z" fill="#FF1616" />
            <path d="M11.5765 6.27556C11.3325 6.03185 10.9372 6.03185 10.6932 6.27556L8.67653 8.29222L6.65987 6.27556C6.41361 6.04609 6.02986 6.05286 5.79185 6.29087C5.55384 6.52888 5.54707 6.91263 5.77653 7.15889L7.7932 9.17556L5.77653 11.1922C5.53282 11.4362 5.53282 11.8315 5.77653 12.0756C6.02055 12.3193 6.41585 12.3193 6.65987 12.0756L8.67653 10.0589L10.6932 12.0756C10.8103 12.1928 10.9692 12.2587 11.1349 12.2589C11.2999 12.2553 11.4575 12.1899 11.5765 12.0756C11.8202 11.8315 11.8202 11.4362 11.5765 11.1922L9.55987 9.17556L11.5765 7.15889C11.8202 6.91488 11.8202 6.51957 11.5765 6.27556Z" fill="#FF1616" />
        </svg>
    )
}

export const DraftIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.4555 10.4061L20.1367 10.0873C19.7242 9.6748 19.0305 9.6748 18.618 10.0873L17.8117 10.8748L16.9867 11.6998V6.20605C16.9867 5.23105 16.1992 4.44355 15.2242 4.44355H15.1117V4.03105C15.1117 3.8248 15.018 3.61855 14.868 3.46855C14.5492 3.1498 14.043 3.1498 13.7242 3.46855C13.5742 3.61855 13.4805 3.8248 13.4805 4.0498V4.4623H12.393V4.0498C12.393 3.84355 12.2992 3.6373 12.1492 3.4873C11.8305 3.16855 11.3242 3.16855 11.0055 3.4873C10.8555 3.6373 10.7617 3.84355 10.7617 4.0498V4.4623H9.67422V4.0498C9.67422 3.84355 9.58047 3.6373 9.43047 3.4873C9.11172 3.16855 8.60547 3.16855 8.28672 3.4873C8.13672 3.6373 8.04297 3.84355 8.04297 4.06855V4.48105H6.95547V4.06855C6.95547 3.8623 6.86172 3.65605 6.71172 3.50605C6.39297 3.1873 5.88672 3.1873 5.56797 3.50605C5.41797 3.65605 5.32422 3.8623 5.32422 4.0873V4.4998H5.21172C4.23672 4.4998 3.44922 5.2873 3.44922 6.2623V19.0686C3.44922 20.0436 4.23672 20.8311 5.21172 20.8311H15.1492C16.1242 20.8311 16.9117 20.0436 16.9117 19.0686V15.3748L19.5742 12.7123L20.3805 11.9061C20.868 11.4936 20.868 10.8186 20.4555 10.4061ZM14.1555 4.0498C14.1555 3.9748 14.2305 3.8998 14.3055 3.8998C14.3992 3.8998 14.4555 3.9748 14.4555 4.0498V5.4748C14.4555 5.5498 14.3992 5.6248 14.3055 5.6248C14.2305 5.6248 14.1555 5.5498 14.1555 5.4748V4.0498ZM11.4555 4.0498C11.4555 3.9748 11.5305 3.8998 11.6055 3.8998C11.6992 3.8998 11.7555 3.9748 11.7555 4.0498V5.4748C11.7555 5.5498 11.6992 5.6248 11.6055 5.6248C11.5305 5.6248 11.4555 5.5498 11.4555 5.4748V4.0498ZM8.75547 4.0498C8.75547 
            3.9748 8.83047 3.8998 8.90547 3.8998C8.99922 3.8998 9.05547 3.9748 9.05547 4.0498V5.4748C9.05547 5.5498 8.98047 5.6248 8.90547 5.6248C8.83047 5.6248 8.75547 5.56855 8.75547 5.4748V4.0498ZM6.07422 4.0498C6.07422 3.9748 6.14922 3.8998 6.22422 3.8998C6.31797 3.8998 6.37422 3.9748 6.37422 4.0498V5.4748C6.37422 5.5498 6.29922 5.6248 6.22422 5.6248C6.14922 5.6248 6.07422 5.56855 6.07422 5.4748V4.0498ZM16.3305 19.0123C16.3305 19.6123 15.843 20.1186 15.2242 20.1186H5.28672C4.68672 20.1186 4.18047 19.6311 4.18047 19.0123V6.20605C4.18047 5.60605 4.66797 5.0998 5.28672 5.0998H5.39922V5.45605C5.39922 5.90605 5.75547 6.2623 6.20547 6.2623C6.65547 6.2623 7.01172 5.90605 7.01172 5.45605V5.0998H8.09922V5.45605C8.09922 5.90605 8.45547 6.2623 8.90547 6.2623C9.35547 6.2623 9.71172 5.90605 9.71172 5.45605V5.0998H10.7992V5.45605C10.7992 5.90605 11.1555 6.2623 11.6055 6.2623C12.0555 6.2623 12.4117 5.90605 12.4117 5.45605V5.0998H13.4992V5.45605C13.4992 5.90605 13.8555 6.2623 14.3055 6.2623C14.7555 6.2623 15.1117 5.90605 15.1117 5.45605V5.0998H15.2242C15.8242 5.0998 16.3305 5.5873 16.3305 6.20605V12.3561C16.3305 12.3561 14.3242 14.3623 13.6492 15.0373C13.6117 15.0748 13.5742 15.1311 13.5555 15.2061L13.1617 16.9686C13.1055 17.2123 13.3117 17.4186 13.5555 17.3623L15.318 16.9686C15.393 16.9498 15.4492 16.9123 15.4867 16.8748C15.5055 16.8561 16.3492 16.0123 16.3305 16.0311V19.0123ZM14.0617 15.9186L14.6055 16.4623L13.9117 16.6123L14.0617 15.9186ZM15.2617 16.1811L14.343 15.2623L16.893 12.7123L18.0367 11.5686L18.9555 12.4873L15.2617 16.1811ZM19.9867 11.4561L19.4055 12.0373L18.4867 11.1186L19.068 10.5373C19.2367 10.3873 19.4992 10.3873 19.668 10.5373L19.9867 10.8561C20.1555 11.0248 20.1555 11.2873 19.9867 11.4561Z" fill="#00008B" />
            <path d="M6.15 8.71875H14.3625C14.55 8.71875 14.7 8.56875 14.7 8.38125C14.7 8.19375 14.55 8.0625 14.3813 8.0625H6.15C5.9625 8.0625 5.8125 8.2125 5.8125 8.4C5.8125 8.5875 5.98125 8.71875 6.15 8.71875Z" fill="#00008B" />
            <path d="M6.15 11.5312H14.3625C14.55 11.5312 14.7 11.3813 14.7 11.1938C14.7 11.0063 14.55 10.875 14.3813 10.875H6.15C5.9625 10.875 5.8125 11.025 5.8125 11.2125C5.8125 11.4 5.98125 11.5312 6.15 11.5312Z" fill="#00008B" />
            <path d="M12.0938 13.6875H6.15C5.9625 13.6875 5.8125 13.8375 5.8125 14.025C5.8125 14.2125 5.9625 14.3625 6.15 14.3625H12.0938C12.2812 14.3625 12.4313 14.2125 12.4313 14.025C12.4313 13.8375 12.2812 13.6875 12.0938 13.6875Z" fill="#00008B" />
            <path d="M11.1938 16.5186H6.15C5.9625 16.5186 5.8125 16.6686 5.8125 16.8561C5.8125 17.0436 5.9625 17.1936 6.15 17.1936H11.175C11.3625 17.1936 11.5125 17.0436 11.5125 16.8561C11.5125 16.6686 11.3625 16.5186 11.1938 16.5186Z" fill="#00008B" />
        </svg>

    )
}

export const AddNewIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 0.5H12.5C14.1569 0.5 15.5 1.84315 15.5 3.5V12.5C15.5 14.1569 14.1569 15.5 12.5 15.5H3.5C1.84315 15.5 0.5 14.1569 0.5 12.5V3.5C0.5 1.84315 1.84315 0.5 3.5 0.5ZM13.7781 13.7781C14.1171 13.4391 14.3075 12.9794 14.3075 12.5V3.5C14.3075 3.02062 14.1171 2.56088 13.7781 2.2219C13.4391 1.88293 12.9794 1.6925 12.5 1.6925H3.5C2.50175 1.6925 1.6925 2.50175 1.6925 3.5V12.5C1.6925 12.9794 1.88293 13.4391 2.22191 13.7781C2.56088 14.1171 3.02062 14.3075 3.5 14.3075H12.5C12.9794 14.3075 13.4391 14.1171 13.7781 13.7781Z" fill="#00008B" />
            <path d="M11 7.4375H8.5625V5C8.5625 4.68934 8.31066 4.4375 8 4.4375C7.68934 4.4375 7.4375 4.68934 7.4375 5V7.4375H5C4.68934 7.4375 4.4375 7.68934 4.4375 8C4.4375 8.31066 4.68934 8.5625 5 8.5625H7.4375V11C7.4375 11.3107 7.68934 11.5625 8 11.5625C8.31066 11.5625 8.5625 11.3107 8.5625 11V8.5625H11C11.3107 8.5625 11.5625 8.31066 11.5625 8C11.5625 7.68934 11.3107 7.4375 11 7.4375Z" fill="#00008B" />
        </svg>
    )
}

export const CancelModalIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_2288_43710)">
                <rect x="4" width="32" height="32" rx="16" fill="#F95428" shape-rendering="crispEdges" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 8.50098H24.5C26.1569 8.50098 27.5 9.84412 27.5 11.501V20.501C27.5 22.1578 26.1569 23.501 24.5 23.501H15.5C13.8431 23.501 12.5 22.1578 12.5 20.501V11.501C12.5 9.84412 13.8431 8.50098 15.5 8.50098ZM25.8279 21.8263C26.1795 21.4746 26.3771 20.9977 26.3771 20.5004V11.5004C26.3771 11.0032 26.1795 10.5262 25.8279 10.1746C25.4763 9.82298 24.9994 9.62543 24.5021 9.62543H15.5021C14.4665 9.62543 13.6271 10.4649 13.6271 11.5004V20.5004C13.6271 20.9977 13.8246 21.4746 14.1763 21.8263C14.5279 22.1779 15.0048 22.3754 15.5021 22.3754H24.5021C24.9994 22.3754 25.4763 22.1779 25.8279 21.8263Z" fill="white" />
                <path d="M22.7712 13.5473C22.5516 13.328 22.1958 13.328 21.9762 13.5473L20.1612 15.3623L18.3462 13.5473C18.1246 13.3408 17.7792 13.3469 17.565 13.5611C17.3508 13.7753 17.3447 14.1207 17.5512 14.3423L19.3662 16.1573L17.5512 17.9723C17.3319 18.1919 17.3319 18.5477 17.5512 18.7673C17.7708 18.9867 18.1266 18.9867 18.3462 18.7673L20.1612 16.9523L21.9762 18.7673C22.0816 18.8728 22.2246 18.9322 22.3737 18.9323C22.5222 18.9291 22.6641 18.8702 22.7712 18.7673C22.9906 18.5477 22.9906 18.1919 22.7712 17.9723L20.9562 16.1573L22.7712 14.3423C22.9906 14.1227 22.9906 13.7669 22.7712 13.5473Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_2288_43710" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.976471 0 0 0 0 0.329412 0 0 0 0 0.156863 0 0 0 0.11 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2288_43710" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2288_43710" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export const DeleteQuestionDraftIcon = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="48" height="48" rx="24" fill="#F95428" fill-opacity="0.08" />
            <rect x="1" y="1" width="48" height="48" rx="24" stroke="#F95428" strokeWidth="2" />
            <path d="M25.5992 34.6003H19.5992C18.2737 34.6003 17.1992 33.5258 17.1992 32.2003L17.1993 17.8004C17.1993 16.4749 18.2738 15.4004 19.5993 15.4004H30.3996C31.7251 15.4004 32.7996 16.4749 32.7996 17.8004V22.0004M27.9996 28.5483C27.9996 27.2516 29.0741 26.2004 30.3996 26.2004C31.7251 26.2004 32.7996 27.2516 32.7996 28.5483C32.7996 29.845 31.7251 30.8961 30.3996 30.8961M30.3996 34.0906V34.0004" stroke="#D53F36" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}