import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  MessageBox,
  MotionDiv,
  CustomTable,
  DeleteButton,
} from "../../components";
import { getError } from "../../utils/error";
import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import CountCard from "../../components/layout/CountCard";
import {
  AddButton,
  BlockButton,
  EditButton,
} from "../../components/layout/CustomTable";
import {
  useAddProfBankDetailsMutation,
  useBlockProfMutation,
  useDeleteProfMutation,
  useGetProfsMutation,
  useUnblockProfMutation,
} from "../../features/apiSlice";
import ModalTemplate from "../../components/modals/ModalTemplate";

export default function Prof() {
  const navigate = useNavigate();
  const [getProfs, { isLoading }] = useGetProfsMutation();
  const [deleteProf, { isLoading:deleteLoading }] = useDeleteProfMutation();
  const [addProfBankDetails, { isLoading: bankLoading }] =  useAddProfBankDetailsMutation();
  const [blockProf,{isLoading:blockLoading}] = useBlockProfMutation();
  const [unblockProf,{isLoading:unblockLoading}] = useUnblockProfMutation();
  const [error, setError] = useState(null);
  // const [users,setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState([]);
  const [profs, setProfs] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [query, setQuery] = useState("");
  const [showBlockModal, setShowBlockModal] = useState(false);
  const handleShowBlockModal = () => setShowBlockModal(true);
  const handleHideBlockModal = () =>{
   setShowBlockModal(false);
   setSelectedProf(null);
  }
  const [numberOfProfs, setNumberOfProfs] = useState(0);
  const [numberOfProfsPerMonth, setNumberOfProfsPerMonth] = useState(0);
  const [activeProfs, setActiveProfs] = useState(0);
  const [blockedProfs, setBlockedProfs] = useState(0);
  const [selectedProf,setSelectedProf] = useState(null);
  const [showCancelModal,setShowCancelModal] = useState(false);
  const handleShowCancelModal = ()=>setShowCancelModal(true);
const handleHideCancelModal = ()=>{
  setShowCancelModal(false);
  setSelectedProf(null);

}
  const curPageHandler = (p) => setCurPage(p);

  const fetchProfs = async () => {
    try {
      const params = `?key=${query}`;

      const data = await getProfs(params).unwrap();
      setProfs(data?.professors);
      setNumberOfProfs(data?.totalProf);
      setNumberOfProfsPerMonth(data?.currentMonthProfessor);
      setNumberOfProfs(data?.totalProf);
      setBlockedProfs(data?.blockedProf);

      setActiveProfs(data?.activeProf);
    } catch (error) {
      getError(error);
    }
  };

  useEffect(() => {
    fetchProfs();
  }, [query]);

  const handleAddBankDetails = async (id) => {
    try {
      const {data} = await addProfBankDetails({ id }).unwrap();
      console.log(data);

      window.location.href = data?.accountLink?.url;
      console.log(data?.accountLink?.url);
    } catch (error) {
      getError(error);
    }
  };


  const handleDeleteProf = async () => {
    try {
      const data = await deleteProf(selectedProf?._id).unwrap();
      // console.log(data);
      toast.success(data?.message);
      handleHideCancelModal();
      fetchProfs();

    } catch (error) {
      getError(error);
    }
  };

  const handleBlockUnblockProf = async(prof)=>{
    try {
      const data = prof?.is_blocked? await unblockProf(prof?._id).unwrap() : await blockProf(prof?._id).unwrap();
      // console.log(data)
    
      fetchProfs();
      handleHideBlockModal();
      setSelectedProf(null)
     toast.success(data?.message);
      
    } catch (error) {
      getError(error);
    }
  }

  const numOfPages = Math.ceil(usersCount / resultPerPage);
  const skip = resultPerPage * (curPage - 1);
  // console.log("no of Page", numOfPages, resultPerPage, usersCount);

  const column = [
    "No.",
    "Prof. Name",
    "Pay Percentage",
    "Email",
    "Area Of Speciality",
    "Bank Actions",
    "Action",
  ];

  console.log(profs);

  return (
    <MotionDiv>
      {error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <>
          <Row>
            {/* <CountCard title={"Total Number Of Prof."} count={numberOfProfs} /> */}
            <CountCard
              title={"Total No. Of Prof."}
              
              // count={tickets.totalTickets && tickets.totalTickets}
              monthValue={numberOfProfsPerMonth}
              yearValue={numberOfProfs}
              loading={isLoading}
              dropdown={true}
              color="var(--yellow-color)"
            />
            <CountCard color="var(--green-color)" title={"Active Prof."} count={activeProfs} loading={isLoading}/>
            <CountCard color="var(--red-color)" title={"Blocked Prof."} count={blockedProfs} loading={isLoading}/>
          </Row>

          <CustomTable
            loading={isLoading}
            column={column}
            rowNo={resultPerPage}
            rowProps={{ setResultPerPage }}
            paging={numOfPages > 0}
            pageProps={{ numOfPages, curPage }}
            pageHandler={curPageHandler}
            search={true}
            searchProps={{ query, setQuery }}
            isTitle="true"
            title="Prof. Details"
            isCreateBtn={true}
            createBtnProps={{
              text: "Add New Prof.",
              createURL: "/admin/profs/add-prof",
            }}
          >
            {profs &&
              profs?.map((prof, i) => (
                <tr key={prof?._id} className="odd" style={{fontSize:'0.8rem'}}>
                  <td className="text-center">{skip + i + 1}</td>
                  <td>{prof?.first_name + " " + prof?.last_name}</td>
                  <td className="">{prof?.pay_percent}%</td>
                  <td>{prof?.email}</td>
                  <td>
                    {prof?.sub_domain
                      ?.map((item, i) => item?.sub_domain_name)
                      .join(" | ")}
                  </td>

                  <td >
                    <div style={{transform:'scale(0.8)'}}>
                      
                    <AddButton
                      func={() => handleAddBankDetails(prof?._id)}
                      title={`${prof?.account_id?'Edit':'Add'} Bank Details`}
                      disabled={bankLoading}
                    />
                    </div>
                  </td>
                  <td className="">
                    <EditButton
                      onClick={() =>
                        navigate(`/admin/profs/edit-prof/${prof?._id}`)
                      }
                    />
                    <BlockButton unblock={prof?.is_blocked} onClick={()=>{
                      setSelectedProf(prof);
                      handleShowBlockModal();
                    } }/>
                    
                    <DeleteButton
                      onClick={() => {
                         setSelectedProf(prof)
                         handleShowCancelModal();
                        console.log("deleted");
                      }}
                    />
                  </td>
                </tr>
              ))}
          </CustomTable>
        </>
      )}


      <ModalTemplate
        show={showBlockModal}
        onHide={handleHideBlockModal}
        src={selectedProf?.is_blocked?'/icons/unblock.png':`/icons/block.png`}
        title={`Are you sure you want to  ${
          selectedProf?.is_blocked ? "unblock" : "block"
        } this Prof. profile from user system ?`}
        
        onConfirm={()=>handleBlockUnblockProf(selectedProf)}
        onDiscard={handleHideBlockModal}
        loading={blockLoading || unblockLoading}
        buttonConfirmTxt={`${selectedProf?.is_blocked ? "Unblock" : "Block"} Now`}
      />

<ModalTemplate
    title={"Are you sure you want to delete this professor?"}
    src={'/icons/question-red.png'}
    onDiscard={handleHideCancelModal}
    show={showCancelModal}
    onHide={handleHideCancelModal}
    onConfirm={handleDeleteProf}
    loading={deleteLoading}
    deleteBtn={true}
/>
      
    </MotionDiv>
  );
}
