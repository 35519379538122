import React, { useEffect, useState } from 'react'

import Modal from 'react-bootstrap/Modal';

import { Link, useNavigate } from 'react-router-dom';


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import { FiTrash } from 'react-icons/fi';
import { getError } from '../../utils/error';
import {  useGetTestsTimedOutMutation } from '../../features/apiSlice';
import { CustomTable, MotionDiv, ViewButton } from '../../components';
import { EditButton } from '../../components/layout/CustomTable';
import { selectOptions } from '../../features/optionSlice';
import ViewTestModal from '../../components/modals/ViewTestModal';



function DeleteQuestionModal(props) {
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='rounded-5'
        >
            <div className='w-100 p-4 text-center'>
                <div
                    className='mx-auto'
                    style={{
                        border: '8px solid #F954282B',
                        borderRadius: '28px',
                        padding: '0.5rem 0.70rem',
                        width: 'max-content',
                    }}
                >
      <FiTrash color="var(--danger-red)"/>
      </div>
                <h5 className='w-50 mx-auto mt-2'>Are you sure you want to delete this test?</h5>
                <p className='w-75 mx-auto my-3'>
                    Lorem ipsum dolor sit amet consectetur. Suscipit nunc tincidunt iaculis sit feugiat platea. Aliquet amet cras amet mauris neque facilisi. Scelerisque interdum elit maecenas.
                </p>
                <div className='d-flex gap-2'>
                    <button
                        className='w-100 rounded-2'
                        style={{ color: 'var(--primary-color)', border: '1px solid var(--primary-color)' }}
                        onClick={props.onHide}
                    >Cancel</button>
                    <button
                        className='border-0 w-100 py-2 rounded-2 text-white'
                        style={{ backgroundColor: 'var(--text-danger)' }}
                        onClick={props.onHide}
                    >Delete
      <FiTrash color="#fff"/>
      </button>
                </div>
            </div>
        </Modal>
    );
}

function DeleteQuestionButton() {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>
            <div style={{ cursor: "pointer" }} onClick={() => setModalShow(true)}><FiTrash /></div>
            <DeleteQuestionModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

const TestTimedOut = () => {

    const {subdomain} = useSelector(selectOptions);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [queSearch,setQueSearch] = useState('') 
    const [testData,setTestData] = useState([])
    const [curPage, setCurPage] = useState(1);
    const [resultPerPage, setResultPerPage] = useState(10);
   
    const curPageHandler = (p) => setCurPage(p);
    const numOfPages = Math.ceil(testData?.length / resultPerPage);
    const skip = resultPerPage * (curPage - 1);
    
   
    const [selectedTest,setSelectedTest] = useState(null)
    const [showTestModal, SetShowTestModal] = useState(false);
    const handleShowTestModal = ()=>SetShowTestModal(true);
    const handleHideTestModal = ()=>SetShowTestModal(false);

    const [getTestTimedOut,{isLoading} ] = useGetTestsTimedOutMutation();
    
    const fetchTests = async()=>{
        try {
            const data = await getTestTimedOut({id:subdomain,params:`&key=${queSearch}`}).unwrap()
            setTestData(data);
        } catch (error) {
            getError(error)
        }
    }
    
        useEffect(()=>{
            
            fetchTests();
        },[])

    return (
        <MotionDiv>

            <div className='w-100 my-3'>
                <Row className='align-items-end'>
                <Col sm={12} md={6} lg={6} xl={6} className='mb-2'>

{/* <h3 style={{ fontSize: '1.5rem', fontWeight: 400 }}>Questions To Be Modified</h3> */}

</Col>
                 
                  
                   
                </Row>
            </div>

            <CustomTable
                loading={isLoading}
                column={["No.", "Test Name", "Type", "No. of Questions", "Time", "Created On", "Action"]}
                paging={false}
                rowNo={resultPerPage}
          rowProps={{ setResultPerPage }}
                pageProps={{ numOfPages, curPage }}
                pageHandler={curPageHandler}
                search={true}
                searchProps={{queSearch,setQueSearch}}
                isTitle="true"
          title="Tests Timed Out"
                >
                   { testData &&

                    testData?.tests?.map((test, idx) =>
                     <tr key={idx + 1} className='odd' >
                   <td className='text-center'>{idx + 1}</td>
                    <td className='text-center'>{test?.test_name}</td>
                    <td className='text-center'>{test?.test_type}</td>
                    <td className='text-center'>
                        <span>{test?.questions_reference?.length}</span>
                    </td>
                    <td className='text-center'>{test?.duration_in_mins}</td>
                    <td className='text-center'>{new Date(test?.createdAt).toLocaleDateString('en-gb')}</td>
                    
                    <td className='mx-auto w-75 d-flex justify-content-center'>
                    <EditButton
                    onClick={() => navigate(`/admin/tests/edit-test/${test?._id}`)}
                    // disabled={data?.status === 'Active'}
                  />
                  <ViewButton
                    onClick={()=>{
                      setSelectedTest(test)
                      handleShowTestModal()
                    }}
                  />
                  
                    </td>
                </tr>)
                      
            }
            </CustomTable>
            <ViewTestModal data={selectedTest} selectedQuestions={selectedTest?.questions_reference} show={showTestModal} onHide={handleHideTestModal} />       

        </MotionDiv>
    )
}

export default TestTimedOut