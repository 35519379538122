import React, { useEffect, useState } from 'react'
import { Col, Modal, Row ,Card,Button, Spinner} from 'react-bootstrap'
import { FiEdit } from 'react-icons/fi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { CustomTable } from '../layout';
import CreateMarkup from '../layout/CreateMarkup';
import { FaCircleXmark } from 'react-icons/fa6';
import { trimText } from '../../utils/trimText';

function ViewTestModal({show,onHide,onConfirm,onCancel,selectedQuestions,data,loading=false}) {

    const navigate = useNavigate();
    const location = useLocation();
   const  isTestPage = location.pathname.includes('/admin/tests/create-test') || location.pathname.includes('/admin/tests/edit-test');
    const [resultPerPage, setResultPerPage] = useState(10);
    const [curPage, setCurPage] = useState(1);
    const curPageHandler = (p) => setCurPage(p);
    const {id} = useParams();
    
    const [topicPercentage, setTopicPercentage] = useState({});
    const [subtopicPercentage, setSubtopicPercentage] = useState({});
    const [topicNames, setTopicNames] = useState({});
    const [subtopicNames, setSubtopicNames] = useState({});
    const [difficultyLevel, setDifficultyLevel] = useState("");
    const [difficultyPer, setDifficultyPer] = useState({});

    useEffect(() => {
        if (selectedQuestions && selectedQuestions.length > 0) {
            const { topicPercentage, subtopicPercentage, topicNames, subtopicNames, difficultyLevel, difficultyPercent } = calculatePercentage(selectedQuestions);
            setTopicPercentage(topicPercentage);
            setSubtopicPercentage(subtopicPercentage);
            setTopicNames(topicNames);
            setSubtopicNames(subtopicNames);
            setDifficultyLevel(difficultyLevel);
            setDifficultyPer(difficultyPercent);
        }
    }, [selectedQuestions]);

    const calculatePercentage = (questions) => {
        let topicCount = {};
        let subtopicCount = {};
        let topicNames = {};
        let subtopicNames = {};
        let difficultyCount = { Basic: 0, Medium: 0, Advanced: 0 };

        questions.forEach(question => {
            const topicId = question?.sub_topic_reference?.topic_reference?._id;
            const subtopicId = question?.sub_topic_reference?._id;
            const difficulty = question?.difficulty_level;
            if (!(topicId in topicNames)) {
                topicNames[topicId] = question?.sub_topic_reference?.topic_reference?.topic_name;
            }

            if (!(subtopicId in subtopicNames)) {
                subtopicNames[subtopicId] = question?.sub_topic_reference?.sub_topic_name;
            }

            topicCount[topicId] = (topicCount[topicId] || 0) + 1;
            subtopicCount[subtopicId] = (subtopicCount[subtopicId] || 0) + 1;
            difficultyCount[difficulty] = (difficultyCount[difficulty] || 0) + 1;
        });

        const totalQuestions = questions.length;

        const topicPercentage = {};
        const subtopicPercentage = {};

        Object.keys(topicCount).forEach(topic => {
            topicPercentage[topicNames[topic]] = ((topicCount[topic] / totalQuestions) * 100).toFixed(2);
        });

        Object.keys(subtopicCount).forEach(subtopic => {
            subtopicPercentage[subtopicNames[subtopic]] = ((subtopicCount[subtopic] / totalQuestions) * 100).toFixed(2);
        });

        const difficultyLevel = calculateDifficultyLevel(difficultyCount, totalQuestions);
        const difficultyPercent = calculateDifficultyPer(difficultyCount, totalQuestions);

        return { topicPercentage, subtopicPercentage, topicNames, subtopicNames, difficultyLevel, difficultyPercent };
    };

    const calculateDifficultyPer = (difficultyCount, totalQuestions) => {
        const difficultyPercentages = {
            Basic: ((difficultyCount.Basic / totalQuestions) * 100).toFixed(2),
            Medium: ((difficultyCount.Medium / totalQuestions) * 100).toFixed(2),
            Advanced: ((difficultyCount.Advanced / totalQuestions) * 100).toFixed(2),
        };
        return difficultyPercentages;
    };

    const calculateDifficultyLevel = (difficultyCount, totalQuestions) => {
        const difficultyPercentages = {
            Basic: (difficultyCount.Basic / totalQuestions) * 100,
            Medium: (difficultyCount.Medium / totalQuestions) * 100,
            Advanced: (difficultyCount.Advanced / totalQuestions) * 100,
        };

        if (difficultyPercentages.Basic > 75) {
            return "Basic";
        } else if (difficultyPercentages.Medium > 75) {
            return "Medium";
        } else if (difficultyPercentages.Advanced > 75) {
            return "Advanced";
        }

        return "Mixed";
    };


    const renderTopicStats = () => {
        return Object.keys(topicPercentage).map((topic, index) => (
            <div key={index} className='mx-2 rounded px-2 py-1' style={{ display: 'inline-block', backgroundColor: 'rgba(224, 234, 255, 1)' }}>
                {`${topic}: `}
                <span style={{ color: 'rgba(0, 0, 139, 1)' }}>{topicPercentage[topic]}%</span>
            </div>
        ));
    };

    const renderSubtopicStats = () => {
        return Object.keys(subtopicPercentage).map((subtopic, index) => (
            <div key={index} className='mx-2 rounded px-2 py-1' style={{ display: 'inline-block', backgroundColor: 'rgba(254, 247, 195, 1)' }}>
                {`${subtopic}: `}
                <span style={{ color: 'rgba(0, 0, 139, 1)' }}>{subtopicPercentage[subtopic]}%</span>
            </div>
        ));
    };

    const renderDifficultyStats = () => {
        return Object.keys(difficultyPer).map((difficulty, index) => (
            <div key={index} className='mx-2 rounded px-2 py-1' style={{ display: 'inline-block', backgroundColor: '#A9FFF552' }}>
            {`${difficulty}: `}
            <span style={{ color: 'rgba(0, 0, 139, 1)' }}>{difficultyPer[difficulty]}%</span>
        </div>
        ));
    };


const numOfPages =1;


    const column = [
        "No.",
        "Question",
        "Topic",
        "Subtopic", 
       
      ];

   

  return (
    <Modal show={show} onHide={onHide} size='xl' centered>
    <Modal.Body>
    <div className='w-100 pb-3'>
      <span
                    onClick={onHide}
                    style={{
                        position: 'absolute',
                        top: '2px',
                        right: '2px',
                        cursor: 'pointer'
                    }}
                >
                    {/* <CancelModalIcon /> */}
                    <FaCircleXmark color="rgba(249, 84, 40, 1)" size={25} />
                </span>
      </div>
        <Card className='p-1 border '>
                <Row>
                    <Col>
                       <p>Test Name: <span style={{color:'rgba(0, 0, 139, 1)'}}>{data?.test_name}</span></p>
                       <p>Total No. of Questions Selected: <span style={{color:'rgba(0, 0, 139, 1)'}}>{selectedQuestions?.length}</span></p>
                       <p>Type of Test: <span style={{color:'rgba(0, 0, 139, 1)'}}>{data?.test_type}</span></p>
                       <p>Time Allotted: <span style={{color:'rgba(0, 0, 139, 1)'}}>{data?.duration_in_mins} Minutes</span></p>
                       <p>Difficulty Level: <span style={{color:'rgba(0, 0, 139, 1)'}}>{difficultyLevel}</span> </p>
                     </Col>
                    <Col md={6} className='p-2'>
                       

                      <div className='custom-scroll py-2' style={{overflowX:'scroll',textWrap:'nowrap'}}>                  
                      {renderTopicStats()}
                        {/* {[{ topic: '1', per: '40' }, { topic: '2', per: '30' }, { topic: '3', per: '20' }].map((t, i) => (
    <div  key={i} className='mx-2 px-2' style={{ display: 'inline-block',backgroundColor:'rgba(224, 234, 255, 1)' }}>
        Topic {t?.topic}: <span style={{color:'rgba(0, 0, 139, 1)'}}>{t?.per}%</span>
    </div>
))} */}
                      </div>                  
                      <div className='custom-scroll py-2 ' style={{overflowX:'scroll',textWrap:'nowrap'}}>                  
                      {renderSubtopicStats()}
                        {/* {[{ subtopic: '1', per: '20' }, { subtopic: '2', per: '40' }, { subtopic: '3', per: '60' },{ subtopic: '4', per: '35' }].map((t, i) => (
    <div  key={i} className='mx-2 px-2' style={{ display: 'inline-block',backgroundColor:'rgba(254, 247, 195, 1)' }}>
        Sub Topic {t?.topic}: <span style={{color:'rgba(0, 0, 139, 1)'}}>{t?.per}%</span>
    </div>
))} */}
                      </div>                  
                      <div className='custom-scroll py-2 ' style={{overflowX:'scroll',textWrap:'nowrap'}}>                  
                      {renderDifficultyStats()}
                        
                      </div>                  
                 
                   </Col>
                   </Row>    
          </Card >            
          <div className=' mt-3 rounded custom-scroll' style={{maxHeight:'50vh',overflowY:'auto'}}>
                    <CustomTable
        //   loading={isLoading}
          column={column}
          rowNo={resultPerPage}
          rowProps={{ setResultPerPage }}
          paging={false}
          pageProps={{ numOfPages, curPage }}
        //   pageHandler={curPageHandler}
        //    search={true}
        //    searchProps={{ searchInput, setSearchInput, setQuery }}
        
        //   isCreateBtn={true}
        //   createBtnProps={{
        //     text: 'Prof',
        //     createURL: '/admin/profs/add-prof',
        //   }}
        >
          {selectedQuestions &&
            selectedQuestions?.map((data, i) => (
              <tr key={data?._id} className="odd text-center" style={{fontSize:'0.75rem'}}>
                <td className="">{i + 1}</td>
                <td className='text-start' style={{maxWidth:'300px',textWrap:'wrap'}}>
                <CreateMarkup content={data?.question?.substring(0, 100)}/>
                </td>
                <td><div className=''>{trimText(data?.sub_topic_reference?.topic_reference?.topic_name,15)}</div></td>
                <td><div className=''>{trimText(data?.sub_topic_reference?.sub_topic_name,15)}</div></td>
              
              </tr>
            ))}
        </CustomTable>
                  
        </div>
    
      {isTestPage? <Row className='mt-3'>

        <Col className="text-end">
          <Button variant="transparent" className="add-btn px-3 m-1" onClick={onCancel}>Go Back</Button>
          <Button className="blue-btn px-3 m-1" type="submit" disabled={loading} onClick={onConfirm}>
          {loading?<Spinner size='sm' />:
          id?'Update Test': 'Create Test'
          
          }
            
            </Button>
        </Col>
      </Row>
      :
      null
}
     
      
    </Modal.Body>
  </Modal>
  )
}

export default ViewTestModal