import React, { useState } from 'react'
import CustomCardDropdown from './CustomCardDropdown'
import { Image } from 'react-bootstrap'

function HomeCountCard({monthValue,yearValue,img,title}) {

const [period,setPeriod] = useState('Per Month')


  return (
    <div className='rounded-3 shadow  p-2 bg-white h-100'>

    <div className='d-flex justify-content-end' >
<CustomCardDropdown  value={period} setValue={setPeriod} dropdownOptions={["Per Month", "Per Year"]} />

</div>
<div className='text-center d-flex justify-content-around px-2'>
   <Image  src={img} className='my-2' style={{height:'42px',borderRadius:'50%',aspectRatio:'1/1'}} />
   <div>
    <h3>{period==='Per Month'?monthValue:period==='Per Year'?yearValue:'N/A'}</h3>
    <p style={{color:'rgba(164, 164, 164, 1)'}}>{title}</p>
   </div>
    </div>
    </div>
  )
}

export default HomeCountCard