import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";


const TextEditor = ({ description, setDescription, name, setForm, form }) => {
  // const [editorData, setEditorData] = useState('<p>Start typing...</p>');

  const [text,setText] = useState('');

  useEffect(()=>{
     if(description)
     {
      setText(description);
     }
  },[description])



  return (
    <div>
      <CKEditor
        editor={Editor}
        data={text}
        onReady={(editor) => {
          console.log("Editor is ready to use!", editor);
        }}
        config={{
          // plugins: [Underline], // Add the Underline plugin here
          toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            // "underline",
            "bulletedList",
            "numberedList",
            "insertTable",
            "undo",
            "redo",
          ],
          table: {
            contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
          },
          // plugins: [Underline],
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          console.log({ event, editor, data });
          setDescription && setDescription(data);
          setForm && setForm({ ...form, [name]: data });
        }}
        onBlur={(event, editor) => {
          console.log("Blur.", editor);
        }}
        onFocus={(event, editor) => {
          console.log("Focus.", editor);
        }}
      />
    </div>
  );
};

export default TextEditor;
