import React, { useEffect, useState } from "react";
import { MotionDiv } from "../../components";
import DomainHeader from "../../components/layout/DomainHeader";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import SearchField from "../../components/layout/SearchField";
import { AddButton } from "../../components/layout/CustomTable";
import { FiEdit } from "react-icons/fi";
import AddTopicSubtopicModal from "../../components/modals/AddTopicSubtopicModal";
import { useParams } from "react-router-dom";
import {
  useCreateSubTopicMutation,
  useDeleteSubTopicByIdMutation,
  useGetSubTopicByIdMutation,
  useGetSubTopicsMutation,
  useGetTopicsMutation,
  useUpdateSubTopicByIdMutation,
} from "../../features/apiSlice";
import { getError } from "../../utils/error";
import { toast } from "react-toastify";
import Skeleton from 'react-loading-skeleton'
import { fetchSubtopics, fetchTopics } from "../../utils/apis";
import { useDispatch, useSelector } from "react-redux";
import { selectOptions, setTopic } from "../../features/optionSlice";
import FormField from "../../components/layout/FormField";
import Title from "../../components/layout/Title";
import BackBtn from "../../components/layout/BackBtn";

function ListOfSubtopics() {

  const dispatch = useDispatch();
  const [createSubtopic, { isLoading: createLoading }] =
    useCreateSubTopicMutation();
  const [getTopics] = useGetTopicsMutation();
  const [getSubtopics,{isLoading}] = useGetSubTopicsMutation();
  const [getSubTopicsById, { isLoading: getByIdLoading }] = useGetSubTopicByIdMutation();
  const [updateSubTopicById, { isLoading: updateLoading }] = useUpdateSubTopicByIdMutation();
  const [deleteSubTopicById, { isLoading: deleteLoading }] = useDeleteSubTopicByIdMutation();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [topics, setTopics] = useState([]);
  const [subtopics, setSubtopics] = useState([]);
  const [references, setReferences] = useState([]);
  const [images, setImages] = useState([]);
  const [preImages, setPreImages] = useState([]);
  const [subtopicId, setSubtopicId] = useState(null);
  // const { id } = useParams();
  const {topic,subdomain} = useSelector(selectOptions);
  const [query,setQuery] = useState('');
  const [showAddModal, setShowModal] = useState(false);
  const handleShowAddModal = () => setShowModal(true);
  const handleHideAddModal = () => {
    setShowModal(false);
    setSubtopicId(null);
    setTitle('');
    setDescription('');
    setReferences([]);
    setImages([]);
    setPreImages([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("sub_topic_name", title);
      formData.append("description", description);
      formData.append("topic_reference", topic);

      // formData.append('references', references);
      references.forEach((ref, index) => {
        formData.append(`references[]`, ref);
      });
     
      preImages.forEach((image, index) => {
        formData.append(`images`, image);
      });

      images.forEach((image, index) => {
        formData.append(`image`, image);
      });

      formData.append(`images`, '');

      console.log(images);

      const data = subtopicId
        ? await updateSubTopicById({ id: subtopicId, data: formData }).unwrap()
        : await createSubtopic(formData).unwrap();

      console.log(data);
      toast.success(data?.message);
      handleHideAddModal();
      fetchSubtopics({setSubtopics,id:topic,getSubtopics,params:`key=${query}`});

    } catch (error) {
      getError(error);
    }
  };

  const fetchSubtopicById = async (id) => {
    setSubtopicId(id);
    try {
      const data = await getSubTopicsById(id).unwrap();
      // console.log(data);
      setTitle(data?.subTopic?.sub_topic_name);
      setDescription(data?.subTopic?.description);
      setReferences(data?.subTopic?.references);
      setPreImages(data?.subTopic?.images);
      handleShowAddModal();
    } catch (error) {
      getError(error);
    }
  };

  const handleDeleteSubtopic = async()=>{
    try {
      const data = await deleteSubTopicById(subtopicId).unwrap();
      toast.success(data?.message);
      handleHideAddModal();
      fetchTopics({setTopics,id:subdomain,getTopics,params:`key=`});

    } catch (error) {
      getError(error)
    }
  }

  useEffect(() => {
    if(subdomain){
      fetchTopics({setTopics,id:subdomain,getTopics,params:`key=`});
    }
  }, [subdomain]);




  useEffect(() => {
    if(!topic){
      setSubtopics([]);
    }
  }, [topic]);

  useEffect(() => {
    if(topic){      
      fetchSubtopics({setSubtopics,id:topic,getSubtopics,params:`key=${query}`});
    }
  }, [query,topic]);



  

  return (
    <MotionDiv>
      <DomainHeader />
      <Row className="">
        <Col className="px-1">
          {/* <p className="fs-4">Add/Edit subtopics from selected topic</p> */}
          <BackBtn/>
        </Col>
        <Col md={6} className="d-flex justify-content-end " style={{display:'block'}}>
        
        <div className=" mt-2 d-flex align-items-center flex-row w-100">
          
          <p className="text-nowrap me-2">Selected Topic:</p>
         
         <div className="w-100">
          <FormField
             
             type={"select"}
             name={'topic'}
             value={topic}
             onChange={(e)=>dispatch(setTopic(e.target.value))} 
            
             options={topics && topics?.map((topic) => ({
               label: topic?.topic_name,
               value: topic?._id,
           }))}

           />
          </div>
        </div>
           
           </Col>
      </Row>
      <Row className="mt-2 border-bottom py-2">
        <Col md={6} className="d-flex align-items-center px-1">
          {/* <p className="my-auto fs-4">List of Subtopics</p> */}
          <Title>List Of Subtopics</Title>
        </Col>

        <Col className="d-flex align-items-center justify-content-end px-1">
          <SearchField placeholder="Search Subtopic" query={query} setQuery={setQuery}/>
        
        
          <AddButton func={handleShowAddModal} title={"Add New Subtopic"} />
        </Col>
      </Row>

        <Row sm={2} className="my-3">
          {isLoading ?
            [...Array(6)].map((_, index) => (
                <Col >
                <Skeleton height={'2.5rem'} className='mb-2' key={index} />
                </Col>
              ))
          :
          subtopics.length > 0 ? subtopics?.map((data, i) => (
            <Col className="px-1" key={data?._id}>
              <Card className="p-2 w-100 ">
                <Row className="align-items-center">
                  <Col className="d-flex justify-content-between">
                  <div style={{maxWidth:'90%'}}>{data?.sub_topic_name}</div>
                  {getByIdLoading && subtopicId === data?._id?
                <Spinner size="sm"/>
                :
                <FiEdit
                className="ms-left"
                color="rgba(0, 0, 139, 1)"
                style={{ cursor: "pointer" }}
                size={20}
                onClick={() => fetchSubtopicById(data?._id)}
              />     
                }
                    
                  </Col>
                </Row>
              </Card>
            </Col>
          ))
        :
        <p>No data</p>
        }
        </Row>
      

      <AddTopicSubtopicModal
        heading={"Subtopic"}
        loading={createLoading || updateLoading}
        title={title}
        setTitle={setTitle}
        description={description}
        setDescription={setDescription}
        onSubmit={handleSubmit}
        show={showAddModal}
        onHide={handleHideAddModal}
        references={references}
        setReferences={setReferences}
        images={images}
        setImages={setImages}
        preImages={preImages}
        setPreImages={setPreImages}
        deleteBtn={subtopicId}
         deleteLoading={deleteLoading}
         onDeleteClick={handleDeleteSubtopic} 
      />
    </MotionDiv>
  );
}

export default ListOfSubtopics;
