import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  MessageBox,
  MotionDiv,
  CustomTable,
  ViewButton,
  DeleteButton,
} from "../../components";
import { getError } from "../../utils/error";
import { imgAddr, useGetPaymentsMutation, useRefundPaymentMutation } from "../../features/apiSlice";
import { Button, Col, Image, Row } from "react-bootstrap";
import ModalTemplate from "../../components/modals/ModalTemplate";
import { FaPhoneVolume, FaRegEnvelope } from "react-icons/fa";
import DateTimeFormater from "../../components/layout/DateTimeFormatter";

export default function Payment() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [usersCount, setUsersCount] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [query, setQuery] = useState("");
  const [user,setUser] = useState(null)
  const [showModal,setShowModal] = useState(false)
  const handleShowModal = ()=>setShowModal(true);
  const handleHideModal = ()=>{
    setShowModal(false)
    setUser(null)
  }

  const [showConfirmModal,setShowConfirmModal] = useState(false)
  const handleShowConfirmModal = ()=>setShowConfirmModal(true);
  const handleHideConfirmModal = ()=>{
    setShowConfirmModal(false)
  }
  const [showCreatedModal, setShowCreatedModal] = useState(false);
  const handleShowCreatedModal = () => {
    handleHideConfirmModal();
    handleHideModal();
    setShowCreatedModal(true);
  }
  const handleHideCreatedModal = () => {
    setShowCreatedModal(false);
     getAllPayments(); 
  };
  const curPageHandler = (p) => setCurPage(p);

  const [getPayments, { isLoading }] = useGetPaymentsMutation();
  const [refundPayment, { isLoading:refundLoading }] = useRefundPaymentMutation();
  const [payments, setPayments] = useState("");

  const numOfPages = Math.ceil(usersCount / resultPerPage);
  const skip = resultPerPage * (curPage - 1);
  // console.log("no of Page", numOfPages, resultPerPage,usersCount);

  const column = [
    "No.",
    "User Name",
    "Email",
    "Area Of Speciality",
    "Payment Received",
    "Prof. Name",
    "Action",
  ];

  useEffect(() => {
    getAllPayments();
  }, [query]);

  const getAllPayments = async () => {
    try {
      const { data } = await getPayments(query);
      console.log(data);
      setPayments(data);
    } catch (error) {
      getError(error);
    }
  };

  const handleRefund = async (id) => {
    try {
      const { data } = await refundPayment(id);
      console.log(data);
      handleShowCreatedModal();
    } catch (error) {
      getError(error);
    }
  };

  const viewUserDataHandler = (user) => {
    localStorage.setItem("profDetails", JSON.stringify(user));
    navigate(`/admin/payment/view`);
  };

  console.log(user);

  return (
    <MotionDiv>
      {error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <>
          <CustomTable
            loading={isLoading}
            column={column}
            rowNo={resultPerPage}
            rowProps={{ setResultPerPage }}
            paging={numOfPages > 0}
            pageProps={{ numOfPages, curPage }}
            pageHandler={curPageHandler}
            search={true}
            searchProps={{ searchInput, setSearchInput, setQuery }}
            isTitle="true"
            title="Payment Details"
          >
            {payments &&
              payments?.payments?.map((payment, i) => (
                <tr key={payment?._id} className="odd">
                  <td className="text-center">{skip + i + 1}</td>
                  <td>
                    {payment?.user?.first_name + " " + payment?.user?.last_name}
                  </td>
                  <td>{payment?.user?.email}</td>
                  <td>{payment?.subdomain?.sub_domain_name}</td>
                  <td className="text-center">£ {payment?.amount}</td>
                  <td>Prof</td>
                  <td className="text-center">
                    <ViewButton onClick={() => viewUserDataHandler(payment)} />
                    {/* <DeleteButton
                      onClick={() => {
                        //  deleteUser(user?._id)
                        console.log("deleted");
                      }}
                    /> */}
                    <Button variant="transparent" onClick={()=>{
                      setUser(payment)
                      handleShowModal()
                     } } style={{fontSize:'0.5rem'}} className="blue-btn p-1 px-2  rounded-pill">
                      Refund
                    </Button>
                  </td>
                </tr>
              ))}
          </CustomTable>
        </>
      )}
      <ModalTemplate size={'lg'}  onHide={handleHideModal} onConfirm={handleShowConfirmModal} onDiscard={handleHideModal} show={showModal} >
      <Row
        className="p-2 align-items-center"
        style={{ backgroundColor: "rgba(238, 244, 255, 1)" }}
      >
        <Col md={5} className="text-end">
          <Image
            src={user?.user?.profile_url && imgAddr + user?.user?.profile_url}
            style={{ aspectRatio: "1/1", height: "90px", borderRadius: "50%",objectFit:'cover' }}
          />
          <br />
        </Col>
        <Col className="align-items-center">
        <div>
          <h3>{user?.user?.first_name && user?.user?.first_name + ' ' + user?.user?.last_name}</h3>
          <p className="m-0">
            <FaPhoneVolume /> <span className='blue'>+{user?.user?.mobile && user?.user?.mobile.slice(2)}</span>
          </p>
          <p className="m-0">
            <FaRegEnvelope /> <span className="blue">{user?.user?.email && user?.user?.email}</span>
          </p>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center my-4">
        <Col className="ps-5">
          <p className="my-3">
          {<>
            <span className="fw-bold">Domain Selected: </span>
             {user?.subdomain?.domain_reference?.domain_name}
             </>
}
          </p>
          <p className="my-3">
            <span className="fw-bold">Payment Received:</span> <span className="blue"> 
            { `£ ${user?.amount}`
            } </span>
          </p>
         
          {/* <p className="my-3">
            <span className="fw-bold">Prof Name:</span>{" "}
            {professorName && professorName}
          </p>
          <p className="my-3">
            <span className="fw-bold">Prof Email:</span>{" "}
            {professorEmail && professorEmail}
          </p> */}
         
        </Col>
        <Col  className="ps-0">
        <p className="my-3">
            <span className="fw-bold">Area Of Speciality:</span>{" "}
            {user?.subdomain && user?.subdomain?.sub_domain_name}
          </p>
         
          <p className="my-3">
            <span className="fw-bold">Payment Made On:</span>{" "}
            { user?.createdAt &&
              <DateTimeFormater createdAt={user?.createdAt} timeReq={false} />
    
            }
          </p>
          <p className="my-3">
            <span className="fw-bold">Plan Validity: </span>
            { `${user?.validity} days`
            }
            
          </p>
        </Col>
      </Row>
      </ModalTemplate>

      <ModalTemplate
        title={"Are you sure you want to make this refund?"}
        
        src={"/icons/question-green.png"}
        loading={refundLoading}
        onDiscard={handleHideConfirmModal}
        show={showConfirmModal}
        onHide={handleHideConfirmModal}
        onConfirm={()=>handleRefund(user?._id)}
      />
      <ModalTemplate
        title={`You have successfully redunded money.`}
        src={"/icons/tick.png"}
        show={showCreatedModal}
        onHide={handleHideCreatedModal}
      />
    </MotionDiv>
  );
}
