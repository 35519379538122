import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
// import { selectOptions } from '../../../features/optionsSlice'
// import { selectAuth } from '../../../features/authSlice'
import { BarChart, LineChart } from '@mui/x-charts'
import { Col, Row } from 'react-bootstrap'
import DomainHeader from "../../components/layout/DomainHeader";
import { selectOptions } from '../../features/optionSlice'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { MotionDiv } from '../../components'
import CustomCard, { CustomCardLabel, CustomCardStatisticalValue } from '../../components/layout/CustomCard'
import { getError } from '../../utils/error'
import { useGetQueGraphMutation, useGetStatsMutation, useGetTestGraphMutation, useGetTransactionGraphMutation, useGetUsersGraphMutation } from '../../features/apiSlice'
import BarGraph from '../../components/layout/BarGraph'
import Title from '../../components/layout/Title'

const StatisticsCardHeader = ({ title }) => {
    return (
        <h3
            style={{
                fontSize: "1.5rem",
                fontWeight: 600
            }}
        >{title}</h3>
    )
}

export const ModifyActionButton = ({ buttonLabel, linkTo }) => {
    const[open,setOpen]= useState(false)
    return (
        <div style={{position:'relative'}}>
        <Link style={{display:`${open? 'unset':'none'}`,position:'absolute',right:'100%'}} to={linkTo}>
            
            <button
            style={{
                width: "5.5rem",
                minWidth: 'max-content',
                backgroundColor: "#FFF",
                border: "0.4px solid #C6C6C64A",
                padding: "0.4rem 1rem",
                borderRadius: "0.25rem",
                boxShadow: "0px 4px 42px 2px #A5A7AA3D"
            }}
           
        >{buttonLabel} </button>
        </Link>

          <span style={{cursor:'pointer'}} onClick={()=>setOpen(!open)}><BsThreeDotsVertical /></span>
        </div>
    )
}

const Statistics = () => {

    // const {user} = useSelector(selectAuth);
    const {subdomain} = useSelector(selectOptions)
    const [getStats,{isLoading:statsLoading}] = useGetStatsMutation();
    const [getUsersGraph,{isLoading:usersGraphLoading}] = useGetUsersGraphMutation(); 
    const [getQueGraph,{isLoading:queGraphLoading}] = useGetQueGraphMutation(); 
    const [getTestGraph,{isLoading:testGraphLoading}] = useGetTestGraphMutation() 
    const [getTransactionGraph,{isLoading:transGraphLoading}] = useGetTransactionGraphMutation() 


   const [data,setData] = useState(null);
   const [userGraph,setUserGraph] = useState(null);
   const [queGraph,setQueGraph] = useState(null);
   const [testGraph,setTestGraph] = useState(null);
   const [transGraph,setTransGraph] = useState(null);

   const fetchGetStats = async()=>{
    try {
        const data = await getStats({id:subdomain}).unwrap();

        setData(data);
    } catch (error) {
        getError(error);
    }
   }
   const fetchGetUsersGraph = async()=>{
    try {
        const data = await getUsersGraph(subdomain).unwrap();

        setUserGraph(data);
    } catch (error) {
        getError(error);
    }
   }
   const fetchQueGraph = async()=>{
    try {
        const data = await getQueGraph(subdomain).unwrap();

        setQueGraph(data);
    } catch (error) {
        getError(error);
    }
   }
   const fetchTestGraph = async()=>{
    try {
        const data = await getTestGraph(subdomain).unwrap();

        setTestGraph(data);
    } catch (error) {
        getError(error);
    }
   }
   const fetchTransactionGraph = async()=>{
    try {
        const data = await getTransactionGraph(subdomain).unwrap();

        setTransGraph(data);
    } catch (error) {
        getError(error);
    }
   }
   
useEffect(()=>{
    if(subdomain){

    
fetchGetStats();
fetchGetUsersGraph();
fetchQueGraph();
fetchTestGraph();
fetchTransactionGraph();
    }
},[subdomain])

    return (
        <MotionDiv>
          <DomainHeader/>

            <div className='my-5'>
                <Title>User Statistics:</Title>
                <hr/>
              <Row>
                <Col md={4} className='p-2'>
                <CustomCard
                    
                    topLeft={<CustomCardLabel label="Total Number of Users" />}
                    // topRight={<VerticalDotsIcon />}
                    bottomLeft={<CustomCardStatisticalValue color={'var(--purple-color)'} value={data?.statics?.users_statics?.registeredUser || 0} />}
                    bottom={<div >
                        {userGraph &&
                         <BarGraph color={'var(--purple-color)'} data={userGraph?.data} />

                    }
                        {/* <LineChart
                    sx={{marginLeft:'-50px'}}
                        xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                        series={[
                          {
                            
                            data: [2, 5.5, 2, 8.5, 1.5, 5],
                            area: true
                          },
                        ]}
                        width={350}
                        height={200}
                      /> */}
                      </div>}
                  
                />
                </Col>
                <Col md={4} className='p-2'>
                <CustomCard
                        topLeft={<CustomCardLabel label="Total Amount Received" />}
                        // topRight={<VerticalDotsIcon />}
                        bottomLeft={<CustomCardStatisticalValue color={'var(--green-color)'} value={`£ ${data?.statics?.users_statics?.totalAmountReceived || 0} `}/>}
                        bottom={<div >
                            {transGraph &&
                            <BarGraph
                            color={'var(--green-color)'}
                            data={transGraph?.data}                          
                          />
                        }
                              </div>}
                    
                    />
                </Col>
                <Col md={4} className='p-2'>
                <CustomCard
                        topLeft={<CustomCardLabel label="New Users Added" />}
                        // topRight={<VerticalDotsIcon />}
                        bottomLeft={<CustomCardStatisticalValue color={'var(--yellow-color)'} value={userGraph?.data[userGraph?.data?.length - 1]?.count ||0} />}
                        bottom={
                              <div>                          
                            {userGraph &&
                            
<BarGraph color={'var(--yellow-color)'} data={userGraph?.data} /> 

                        }
                        </div>
                          }
                     
                    />
                </Col>
              </Row>
               
            </div>

            <div className='my-5'>
                <Title>Question Statistics:</Title>
<hr/>
<Row>
    <Col md={4} className='p-2'>
    <CustomCard
                        topLeft={<CustomCardLabel label="Number of Questions Prepared" />}
                        // topRight={<VerticalDotsIcon />}
                        bottomLeft={<CustomCardStatisticalValue color={'var(--blue-color)'} value={data?.statics?.questions_statics?.total_question || 0} />}
                        // bottomRight={<CustomCardDropdown dropdownOptions={["Per Week", "Per Month", "Per Year"]} />}
                     
                        bottom={<div >
                            {queGraph &&
                     <BarGraph color={'var(--blue-color)'} data={queGraph?.data}/>
                       }
                        </div>
                        }
                    />
    </Col>
    <Col md={4} className='p-2'>
    <CustomCard
                        topLeft={<CustomCardLabel label="Number of Tests Prepared" />}
                        // topRight={<VerticalDotsIcon />}
                        bottomLeft={<CustomCardStatisticalValue
                             color={'var(--danger-red)'}
                             value={data?.statics?.questions_statics?.total_test || 0} 
                         
                             />}
                        // bottomRight={<CustomCardDropdown dropdownOptions={["Per Week", "Per Month", "Per Year"]} />}
                        bottom={<div >
                            {testGraph &&
                                                         <BarGraph
                                                         color={'var(--danger-red)'} data={testGraph?.data} />

                        }
                        </div>
                        }
                    
                    />
    </Col>
    <Col md={4}>
    
    </Col>
</Row>

                
            </div>

            <div className='my-5'>
                <Title>Modify Tests:</Title>
<hr/>
<Row>
<Col md={4} className='p-2'>
<CustomCard
                        topLeft={<CustomCardLabel label={"Number of Tests Modified"} />}
                        topRight={<ModifyActionButton buttonLabel='View' linkTo='view/modified-tests' />}
                        bottomLeft={<CustomCardStatisticalValue
                             value={data?.statics?.tests_statics?.testmodified || 0} 
                             />}
                    
                       
                    />  
</Col>
<Col md={4} className='p-2'>
  
<CustomCard
                        topLeft={<CustomCardLabel label={"Number of Tests Timed Out"} />}
                        topRight={<ModifyActionButton buttonLabel='Edit' linkTo='edit/tests-timed-out' />}
                        bottomLeft={<CustomCardStatisticalValue value={data?.statics?.tests_statics?.timedout || 0} />}
                      
                    />
</Col>
</Row>
<Row className='mt-2'>
<Col md={4} className='p-2'>
<CustomCard
                        topLeft={<CustomCardLabel label={"Number of Question Modified"} />}
                        topRight={<ModifyActionButton buttonLabel='View' linkTo='view/modified-questions' />}
                        bottomLeft={<CustomCardStatisticalValue 
                            value={data?.statics?.questions_statics?.modifiedquestion || 0}
                            />}
                       
                    />
</Col>
<Col md={4} className='p-2'>
<CustomCard
                        topLeft={<CustomCardLabel label={"Questions to be Modify"} />}
                        topRight={<ModifyActionButton buttonLabel='Edit' linkTo='edit/modified-questions' />}
                        bottomLeft={<CustomCardStatisticalValue value={data?.statics?.questions_statics?.question_tobe_modified || 0} />}
                      
                     
                    />
</Col>
</Row>

              
            </div>

          
        </MotionDiv>
    )
}

export default Statistics