import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { FaPhoneVolume, FaRegEnvelope } from "react-icons/fa";
import { MotionDiv } from "../../components";
import { imgAddr } from "../../features/apiSlice";
import DateTimeFormater from "../../components/layout/DateTimeFormatter";

function ViewUser() {
  const [user, setUser] = useState("");

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userDetails"));
    setUser(userData);
  }, []);

  const professorName = localStorage.getItem("professorName");
  const professorEmail = localStorage.getItem("professorEmail");

  return (
    <MotionDiv>
      <h3 className="text-center me-3 my-3">User Details</h3>
      <Row
        className="p-2 align-items-center"
        style={{ backgroundColor: "rgba(238, 244, 255, 1)" }}
      >
        <Col md={5} className="text-end">
          <Image
            src={user?.profile_url && imgAddr + user?.profile_url}
            style={{ aspectRatio: "1/1", height: "90px", borderRadius: "50%",objectFit:'cover' }}
          />
          <br />
        </Col>
        <Col className="align-items-center">
        <div>
          <h3>{user?.first_name && user?.first_name + ' ' + user?.last_name}</h3>
          <p className="m-0">
            <FaPhoneVolume /> <span className='blue'>+{user?.mobile && user?.mobile.slice(2)}</span>
          </p>
          <p className="m-0">
            <FaRegEnvelope /> <span className="blue">{user?.email && user?.email}</span>
          </p>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center my-4">
        <Col lg={4} className="ps-5">
          <p className="my-3">
          {user?.domain && <>
            <span className="fw-bold">Domain Selected: </span>
             {user?.domain?.domain_name}
             </>
}
          </p>
          <p className="my-3">
            <span className="fw-bold">Area Of Speciality:</span>{" "}
            {user?.subdomain && user?.subdomain?.sub_domain_name}
          </p>
          <p className="my-3">
            <span className="fw-bold">Prof Name:</span>{" "}
            {professorName && professorName}
          </p>
          <p className="my-3">
            <span className="fw-bold">Prof Email:</span>{" "}
            {professorEmail && professorEmail}
          </p>
         
        </Col>
        <Col lg={4} className="ps-0">
          <p className="my-3">
            <span className="fw-bold">Plan Selected:</span> <span className="blue"> 
            {user?.active_transactions?.length >0 ? `£ ${user.active_transactions[0]?.amount}`
            :
            'N/A'} </span>
          </p>
          <p className="my-3">
            <span className="fw-bold">Plan Start Date:</span>{" "}
            {user?.active_transactions?.length >0 ?
              // user?.active_transactions[0]?.createdAt.split("T")[0]
              <DateTimeFormater createdAt={user?.active_transactions[0]?.createdAt} timeReq={false} />
            :
            'N/A' 
            }
          </p>
          <p className="my-3">
            <span className="fw-bold">Plan Validity: </span>
            {user?.active_transactions?.length >0 ?
              `${user?.active_transactions[0]?.validity} days`
            :
            'N/A'
            }
            
          </p>
        </Col>
      </Row>
    </MotionDiv>
  );
}

export default ViewUser;
