import React, { useEffect, useState } from 'react'

import Modal from 'react-bootstrap/Modal';

import { Link, useNavigate } from 'react-router-dom';


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import { FiTrash } from 'react-icons/fi';
import { getError } from '../../utils/error';
import { useGetModifiedQuesMutation } from '../../features/apiSlice';
import { CustomTable, MotionDiv, ViewButton } from '../../components';
import CreateMarkup from '../../components/layout/CreateMarkup';
import QuestionModal from '../../components/modals/QuestionModal';
import { EditButton } from '../../components/layout/CustomTable';
import { selectOptions } from '../../features/optionSlice';



function DeleteQuestionModal(props) {
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='rounded-5'
        >
            <div className='w-100 p-4 text-center'>
                <div
                    className='mx-auto'
                    style={{
                        border: '8px solid #F954282B',
                        borderRadius: '28px',
                        padding: '0.5rem 0.70rem',
                        width: 'max-content',
                    }}
                >
      <FiTrash color="var(--danger-red)"/>
      </div>
                <h5 className='w-50 mx-auto mt-2'>Are you sure you want to delete this question?</h5>
                <p className='w-75 mx-auto my-3'>
                    Lorem ipsum dolor sit amet consectetur. Suscipit nunc tincidunt iaculis sit feugiat platea. Aliquet amet cras amet mauris neque facilisi. Scelerisque interdum elit maecenas.
                </p>
                <div className='d-flex gap-2'>
                    <button
                        className='w-100 rounded-2'
                        style={{ color: 'var(--primary-color)', border: '1px solid var(--primary-color)' }}
                        onClick={props.onHide}
                    >Cancel</button>
                    <button
                        className='border-0 w-100 py-2 rounded-2 text-white'
                        style={{ backgroundColor: 'var(--text-danger)' }}
                        onClick={props.onHide}
                    >Delete
      <FiTrash color="#fff"/>
      </button>
                </div>
            </div>
        </Modal>
    );
}

function DeleteQuestionButton() {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>
            <div style={{ cursor: "pointer" }} onClick={() => setModalShow(true)}><FiTrash /></div>
            <DeleteQuestionModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

const ModifiedQuestions = () => {

    const {subdomain} = useSelector(selectOptions);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [queSearch,setQueSearch] = useState('') 
    const [questionData,setQuestionData] = useState([])
    const [showQueModal, SetShowQueModal] = useState(false);
    const [selectedQuestion,setSelectedQuestion] = useState(null)
    const [selectedIdx,setSelectedIdx] = useState(0)
    const [curPage, setCurPage] = useState(1);
    const [resultPerPage, setResultPerPage] = useState(10);
   
    const curPageHandler = (p) => setCurPage(p);
    const numOfPages = Math.ceil(questionData?.length / resultPerPage);
    const skip = resultPerPage * (curPage - 1);
    
    const handleShowQueModal = ()=>{
      SetShowQueModal(true)
    };
    const handleHideQueModal = ()=>SetShowQueModal(false);


    const [getModifiedQues,{isLoading} ] = useGetModifiedQuesMutation();
    
    const fetchQuestions = async()=>{
        try {
            const data = await getModifiedQues({id:subdomain,params:`&key=${queSearch}`}).unwrap()
            setQuestionData(data);
        } catch (error) {
            getError(error)
        }
    }
    
        useEffect(()=>{
            
            fetchQuestions();
        },[])

    return (
        <MotionDiv>

            <div className='w-100 my-3'>
                <Row className='align-items-end'>
                <Col sm={12} md={6} lg={6} xl={6} className='mb-2'>

{/* <h3 style={{ fontSize: '1.5rem', fontWeight: 400 }}>Questions To Be Modified</h3> */}

</Col>
                 
                  
                   
                </Row>
            </div>

            <CustomTable
                loading={isLoading}
                column={["No", "Question", "D.L", "Type", "Action"]}
                paging={false}
                rowNo={resultPerPage}
          rowProps={{ setResultPerPage }}
                pageProps={{ numOfPages, curPage }}
                pageHandler={curPageHandler}
                search={true}
                searchProps={{queSearch,setQueSearch}}
                isTitle="true"
          title="Modified Questions"
                >
                   { questionData &&

                    questionData?.questions?.map((question, idx) =>
                     <tr key={idx + 1} className='odd' style={{fontSize:'0.75rem'}}>
                    <td className='px-3 text-center' style={{ fontSize: '0.75rem' }}>{idx + 1}</td>
                    <td className='px-3'
                        style={{
                            width: "40%",
                            fontSize: '12px'
                        }}
                    >
                        {/* {question?.question} */}
                            <CreateMarkup content={question?.question?.question?.substring(0, 100)}/>
                            </td>
                    <td className='px-3' style={{ minWidth: "max-content" }}>
                        <p
                            className='w-75 mx-auto shadow text-center py-1 px-1 rounded-1 bg-white'
                            style={{ fontSize: '0.75rem', fontWeight: 400 }}
                        >
                            {question?.question?.difficulty_level}
                            </p>
                    </td>
                    <td className='px-1' style={{ minWidth: "max-content" }}>
                        <p
                            className='mx-auto shadow text-center py-1 px-0 mx-auto w-75 rounded-1 bg-white'
                            style={{ fontSize: '0.75rem', fontWeight: 400 }}
                        >
                            {question?.question?.question_type}
                            </p>
                    </td>
                    <td className='mx-auto w-75 d-flex justify-content-center'>
                    <ViewButton
                    onClick={()=>{
                      setSelectedIdx(idx);
                      setSelectedQuestion(question?.question);
                      handleShowQueModal();
                    
                    }}
                  />
                  <EditButton
                  onClick={() => navigate(`/admin/questions/edit-question/${question?.question?._id}`)}
                />
                  
                    </td>
                </tr>)
                      
            }
            </CustomTable>
            <QuestionModal question={selectedQuestion} idx={selectedIdx} show={showQueModal} onHide={handleHideQueModal} />

        </MotionDiv>
    )
}

export default ModifiedQuestions