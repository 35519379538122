import React, { useState } from "react";
import FormField from "../layout/FormField";
import { Modal, Button, Row, Col, Card, Spinner, Form } from "react-bootstrap";
import { HiOutlineTrash } from "react-icons/hi";
import UploadImages from "../layout/UploadImages";
import References from "../layout/References";
import { useCreateDomainMutation } from "../../features/apiSlice";
import TextEditor from "../layout/TextEditor";
import ModalTemplate from "./ModalTemplate";

function AddTopicSubtopicModal({
  show,
  onHide,
  heading,
  title,
  description,
  setTitle,
  setDescription,
  onSubmit,
  loading = false,
  references,
  setReferences,
  images,
  setImages,
  preImages,
  setPreImages,
  deleteBtn = false,
  deleteLoading=false,
  onDeleteClick
}) {


  const [showDeleteModal,setShowDeleteModal] = useState(false);
  return (
    <Modal show={show} onHide={onHide} size="xl" centered>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Row >
            <h6 className="blue">Enter {heading} Name:</h6>

            <Col>
              <FormField
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Col>
            <Col sm={3} className="text-end">
            
            {deleteBtn
            
            &&
            <Button variant="transparent" disabled={deleteLoading} className="mb-3 delete-btn" onClick={()=>setShowDeleteModal(true)}>
           {deleteLoading?
          <Spinner size="sm"/>:
             <>
           Delete {heading} <HiOutlineTrash size={20} />
           </>
}
          </Button>
            }
             

            </Col>
          </Row>
   {/* <hr/> */}
          <Card.Body className="border-top py-2 border-bottom">
            <Row>
              <Col><h6 className="blue">Description:</h6></Col>

             
            </Row>
            {/* <FormField
              type={"text"}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              as={"textarea"}
              rows={4}
            /> */}
            <TextEditor description={description} setDescription={setDescription}/>
           
            <Row className="my-2">
              
              <Col>
                {setReferences &&
                <References
                  references={references}
                  setReferences={setReferences}
                />
                }
              </Col>
            </Row>
            <Row className="my-2">
              <Col >
              {setImages &&
                <UploadImages images={images} setImages={setImages} preImages={preImages} setPreImages={setPreImages}/>
}
                </Col>
              
            </Row>
          </Card.Body>
          <Row className="my-3">
            <Col className="text-center ">
              <Button
                variant="transparent"
                className="del-btn m-1 px-3"
                onClick={onHide}
              >
                Cancel
              </Button>
              <Button
                variant="transparent"
                type="submit"
                disabled={loading}
                className="add-btn m-1 px-3"
              >
                
                {loading ? <Spinner size="sm" /> : "Save Details"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>

      <ModalTemplate
    title={`Are you sure you want to delete this ${heading}?`}
    src={'/icons/question-red.png'}
    onDiscard={()=>setShowDeleteModal(false)}
    show={showDeleteModal}
    onHide={()=>setShowDeleteModal(false)}
    onConfirm={async()=>{
     await onDeleteClick();
      setShowDeleteModal(false)
    }
    }
    loading={deleteLoading}
    deleteBtn={true}
/>

    </Modal>
  );
}

export default AddTopicSubtopicModal;
