import React, { useEffect, useState } from 'react'
import { MotionDiv } from '../../components'
import DomainHeader from '../../components/layout/DomainHeader'
import { Card, Col, Container, Row } from 'react-bootstrap'
import SearchField from '../../components/layout/SearchField'
import { AddButton } from '../../components/layout/CustomTable'
import { FiEdit } from 'react-icons/fi'
import AddTopicSubtopicModal from '../../components/modals/AddTopicSubtopicModal'
import {useNavigate,useParams} from 'react-router-dom'
import { useGetSubdomainsMutation } from '../../features/apiSlice'
import Skeleton from 'react-loading-skeleton'
import { getError } from '../../utils/error'
import { fetchSubdomains } from '../../utils/apis'
import { useSelector } from 'react-redux'
import { selectOptions } from '../../features/optionSlice'
import Title from '../../components/layout/Title'
import BackBtn from '../../components/layout/BackBtn'

function ListOfAreaOfSpeciality() {

  const [showAddModal,setShowModal] = useState(false);
  const {domain} = useSelector(selectOptions);
  const [getSubdomains,{isLoading:subdomainLoading}] = useGetSubdomainsMutation();
  const [subdomains,setSubdomains] = useState([]);
  // const {id} = useParams()
  const handleShowAddModal = ()=>setShowModal(true)
  const handleHideAddModal = ()=>setShowModal(false)
 const [query,setQuery] = useState('')
  const navigate = useNavigate()

  


useEffect(()=>{
  fetchSubdomains({getSubdomains,setSubdomains,id:domain,params:`key=${query}`});
},[query,domain])



  return (
   <MotionDiv>
     
       <DomainHeader/>
       <Row>
        <Col className='px-1'>
        
       <BackBtn/>
        </Col>
       </Row>
       {/* <p className='my-3 fs-4'>Add/Edit Any Specialty Area From Below</p> */}
       <Row className='border-bottom py-2 '>
            <Col  className='d-flex align-items-end justify-content-between px-1'>
       <Title>List Of Specialty</Title>
              {/* <p className="fs-4">List Of Specialty</p> */}
           
           <div className='d-flex flex-row'>
              <SearchField query={query} setQuery={setQuery} placeholder='Search Area Of Speciality'/>
            
              <AddButton url='/admin/domains/add-specialty' title={"Add Specialty Area"} />
            </div>
            </Col>
          </Row>

         
            <Row  md={3} sm={2} className="my-3 ">
            {subdomainLoading ?
  [...Array(8)].map((_, index) => (
    <Skeleton height={'2.5rem'} className='mb-2' key={index} />
  ))

:
              subdomains.length >0 ? subdomains?.map((data, i) => (
                <Col className="p-1">
                  <Card className="p-2 w-100 ">
                    <Row className="">
                      <Col className='d-flex justify-content-between'>
                     <div>
                        {data?.sub_domain_name}
                     </div>
                     <div>
                        <FiEdit
                          className="ms-left"
                          color="rgba(0, 0, 139, 1)"
                          size={20}
                          onClick={()=>navigate(`/admin/domains/edit-specialty/${data?._id}`)}
                          style={{cursor:'pointer'}}
                        />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))
            :
            <p>No data</p>
            }
            </Row>
          

<AddTopicSubtopicModal title={'Specialty'} show={showAddModal} onHide={handleHideAddModal} />

   </MotionDiv>
  )
}

export default ListOfAreaOfSpeciality