import React, { useEffect, useState } from "react";
import { MotionDiv } from "../../components";
import DomainHeader from "../../components/layout/DomainHeader";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import SearchField from "../../components/layout/SearchField";
import { AddButton } from "../../components/layout/CustomTable";
import { FiEdit } from "react-icons/fi";
import AddTopicSubtopicModal from "../../components/modals/AddTopicSubtopicModal";
import {
  useCreateTopicMutation,
  useDeleteTopicByIdMutation,
  useGetTopicByIdMutation,
  useGetTopicsMutation,
  useUpdateTopicByIdMutation,
} from "../../features/apiSlice";
import { getError } from "../../utils/error";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchTopics } from "../../utils/apis";
import Skeleton from 'react-loading-skeleton'
import { useSelector } from "react-redux";
import { selectOptions } from "../../features/optionSlice";
import Title from "../../components/layout/Title";
import BackBtn from "../../components/layout/BackBtn";

function ListOfTopics() {
  const [createTopic, { isLoading: createLoading }] = useCreateTopicMutation();
  const [getTopicById, { isLoading: getByIdLoading }] =
    useGetTopicByIdMutation();
  const [updateTopicById, { isLoading: updateLoading }] =
    useUpdateTopicByIdMutation();
  const [deleteTopicById, { isLoading: deleteLoading }] =
    useDeleteTopicByIdMutation();
  const [getTopics,{isLoading}] = useGetTopicsMutation();

  const [topics, setTopics] = useState([]);
  // const { id } = useParams();
  const {subdomain} = useSelector(selectOptions)
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [references, setReferences] = useState([]);
  const [images, setImages] = useState([]);
  const [preImages, setPreImages] = useState([]);
  const [topicId, setTopicId] = useState(null);
  const [query,setQuery] = useState('')
  const [showAddModal, setShowModal] = useState(false);
  const handleShowAddModal = () => setShowModal(true);
  const handleHideAddModal = () => {
    setShowModal(false);
    setTopicId(null);
    setTitle('');
    setDescription('');
    setReferences([]);
    setImages([]);
    setPreImages([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("topic_name", title);
      formData.append("description", description);
      formData.append("sub_domain_reference", subdomain);

      // formData.append('references', references);
      references.forEach((ref, index) => {
        formData.append(`references[]`, ref);
      });

      preImages.forEach((image, index) => {
        formData.append(`images`, image);
      });
      formData.append(`images`, '');

      images.forEach((image, index) => {
        formData.append(`image`, image);
      });


      console.log(images);

      const data = topicId
        ? await updateTopicById({ id: topicId, data: formData }).unwrap()
        : await createTopic(formData).unwrap();

      console.log(data);
      toast.success(data?.message);
      handleHideAddModal();
      // fetchTopics();
      fetchTopics({setTopics,id:subdomain,getTopics,params:`key=${query}`});

    } catch (error) {
      getError(error);
    }
  };

  const fetchTopicById = async (id) => {
    setTopicId(id);
    try {
      const data = await getTopicById(id).unwrap();
      console.log(data);
      setTitle(data?.topic?.topic_name);
      setDescription(data?.topic?.description);
      setReferences(data?.topic?.references);
      setPreImages(data?.topic?.images);
      handleShowAddModal();
    } catch (error) {
      getError(error);
    }
  };

  const handleDeleteTopic = async()=>{
    try {
      const data = await deleteTopicById(topicId).unwrap();
      toast.success(data?.message);
      handleHideAddModal();
      fetchTopics({setTopics,id:subdomain,getTopics,params:`key=${query}`});

    } catch (error) {
      getError(error)
    }
  }


  useEffect(()=>{
    if(subdomain){

      fetchTopics({setTopics,id:subdomain,getTopics,params:`key=${query}`});
    }
},[query,subdomain])

  

  return (
    <MotionDiv>
      <DomainHeader />
      <Row>
        <Col className="px-1">
        
      <BackBtn/>
        </Col>
      </Row>
      {/* <p className="my-3 fs-4" >
        Add/Edit Topics From Selected Area Of Speciality
      </p> */}
      <Row className="border-bottom py-2">
      <Col md={6} className="px-1 d-flex align-items-center">
      {/* <p className="my-auto fs-4">List Of Topics</p> */}
      <Title>List Of Topics</Title>
        </Col>

        <Col className="px-1 d-flex align-items-center justify-content-end">
          <SearchField placeholder="Search Topic" query={query} setQuery={setQuery}/>
        
          <AddButton func={handleShowAddModal} title={"Add New Topic"} />
        </Col>
      </Row>

        <Row sm={2} className="my-3 ">
        {isLoading ?
            [...Array(6)].map((_, index) => (
                <Col >
                <Skeleton height={'2.5rem'} className='mb-2' key={index} />
                </Col>
              ))
          : topics.length >0 ? topics?.map((item, i) => (
            <Col className="px-1">
              <Card className="p-2 w-100 ">
                <Row className="align-items-center">
                  <Col className="d-flex justify-content-between">
                  <div style={{maxWidth:'90%'}}>{item?.topic_name}</div>
                  {getByIdLoading && topicId === item?._id?
                <Spinner size="sm"/>
                :
                    <FiEdit
                      className="ms-left"
                      color="rgba(0, 0, 139, 1)"
                      style={{ cursor: "pointer" }}
                      size={20}
                      onClick={() => fetchTopicById(item?._id)}
                    />
                  }
                  </Col>
                </Row>
              </Card>
            </Col>
          ))
          :
          <p>No data</p>
          
          }
        </Row>
 

<AddTopicSubtopicModal
        heading={"Topic"}
        loading={createLoading || updateLoading}
        title={title}
        setTitle={setTitle}
        description={description}
        setDescription={setDescription}
        onSubmit={handleSubmit}
        show={showAddModal}
        onHide={handleHideAddModal}
        references={references}
        setReferences={setReferences}
        images={images}
        setImages={setImages}
        preImages={preImages}
        setPreImages={setPreImages}
        deleteBtn={topicId}
        deleteLoading={deleteLoading}
        onDeleteClick={handleDeleteTopic} 
      />
    </MotionDiv>
  );
}

export default ListOfTopics;
