import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa6';
import ReferenceEditor from './ReferenceEditor';
import { FiTrash } from 'react-icons/fi';

function References({ references, setReferences }) {
  const addReference = () => {
    const newReferences = [...references, ''];
    setReferences(newReferences);
  };

  const removeReference = (index) => {
    const newReferences = [...references];
    newReferences.splice(index, 1);
    setReferences(newReferences);
  };

  const handleReferenceChange = (index, value) => {
    const newReferences = [...references];
    newReferences[index] = value;
    setReferences(newReferences);
  };

  return (
    <Card className="border-0  my-3">
      <Card.Header className="bg-white p-0 ">
        <h6 className='blue'>References:</h6></Card.Header>
      <Card.Body className='border-bottom'>
      {references?.map((reference, index) => (
        <div key={index} className='mb-2' style={{ display: 'flex', alignItems: 'center' }}>
          {/* <input
            type="text"
            value={reference}
            onChange={(e) => handleReferenceChange(index, e.target.value)}
            style={{ marginRight: '10px' }}
            className='border-1 rounded shadow'
          /> */}
          <ReferenceEditor reference={reference} onChange={(e) => handleReferenceChange(index, e.target.value)}/>
          <Button variant="transparent" className=' px-1 py-0 m-1' style={{borderWidth:'0px'}} onClick={() => removeReference(index)}>
          <FiTrash color="var(--danger-red)"/>

          </Button>
        </div>
      ))}
        <Button variant="transparent" className='blue' onClick={addReference}>
          <FaPlus/>
        </Button>
      </Card.Body>
    </Card>
  );
}

export default References;
