import React, { useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import FormField from "./FormField";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ModifyActionButton } from "../../pages/reports/statistics";
import CustomCardDropdown from "./CustomCardDropdown";

function CountCard({
  title,
  count,
  viewDetails,
  textColor = "#000",
  color="#000",
  report,
  loading = false,
  monthValue,
  yearValue,
  dropdown = false
}) {

  const [period,setPeriod] = useState('Per Month')

  return (
    <Col md={!report && 3} className=" mb-2" >
      <Card className=" rounded-3" style={{ height: "100%",zIndex:2 ,borderLeft:`10px solid ${color}`}}>
        <Card.Body>
          <Row>
            <Col style={{ color: "rgb(71, 84, 103)" }}>
              
              <p
                style={{
                  fontSize: "14px",
                  fontWeight:500
                }}
              >
                {title}
              </p>
            </Col>
            {viewDetails && (
              <Col sm={2} className="text-end">
                {/* <Button
                  variant="transparent"
                  className=" py-0 border text-nowrap"
                  onClick={viewDetails}
                >
                  View Details{" "}
                  <BsThreeDotsVertical className="mb-1" size={"0.9rem"} />{" "}
                </Button> */}
                <ModifyActionButton buttonLabel='View' linkTo={viewDetails} />
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              {!loading ? (
                <h3 style={{ color: color,marginTop:'5px' }} >
                  {Number.isFinite(count) ?count:null}
                  
              {period==='Per Month'?monthValue:period==='Per Year'?yearValue:'N/A'}</h3>

              ) : (
                <Spinner size="sm" />
              )}
            </Col>
            <Col md={7} className="p-0 d-flex justify-content-end">

           {dropdown && <CustomCardDropdown  value={period} setValue={setPeriod} dropdownOptions={["Per Month", "Per Year"]} />}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default CountCard;
