import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import SearchField from '../../components/layout/SearchField'
import { IoAddCircleSharp } from 'react-icons/io5'
import { MdDashboard } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { FaAngleRight, FaArrowRight, FaChevronRight } from 'react-icons/fa'
import { FaCircleInfo } from 'react-icons/fa6'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import { AddButton } from '../../components/layout/CustomTable'
import { MotionDiv } from '../../components'
import { useGetDomainsMutation, useGetSubdomainsMutation } from '../../features/apiSlice'
import { getError } from '../../utils/error'
import Skeleton from 'react-loading-skeleton'
import { fetchDomains, fetchSubdomains } from '../../utils/apis'
import { useDispatch, useSelector } from 'react-redux'
import { selectOptions, setDomain, setSubdomain } from '../../features/optionSlice'
import Title from '../../components/layout/Title'


function Domains() {

     
const navigate = useNavigate();
const dispatch = useDispatch();
const {domain,subdomain} = useSelector(selectOptions);

const [getDomains,{isLoading:domainLoading} ] = useGetDomainsMutation();
const [getSubdomains,{isLoading:subdomainLoading} ] = useGetSubdomainsMutation();
const [domains,setDomains] = useState([]);
const [subdomains,setSubdomains] = useState([]);
const [selectedDomain,setSelectedDomain] = useState(null)

const [domainQuery,setDomainQuery] = useState('')
const [subDomainQuery,setSubDomainQuery] = useState('')



// const fetchDomains = async()=>{
//     try {
//       const data = await getDomains().unwrap();
//       console.log(data);
//       setDomains(data?.domains);
//     } catch (error) {
//       getError(error);
//     }
//   }


// const fetchSubdomains = async()=>{
//     try {
//       const data = await getSubdomains(selectedDomain).unwrap();
//       console.log(data);
//       setSubdomains(data?.subDomains);
//     } catch (error) {
//       getError(error);
//     }
//   }




  useEffect(()=>{
     if(domains.length>0){
        // setSelectedDomain(domains[0]?._id);
        dispatch(setDomain(domains[0]?._id))
     }
  },[domains])

  useEffect(()=>{
        fetchDomains({getDomains,setDomains,params:`key=${domainQuery}`});
  },[domainQuery])

  useEffect(()=>{
    if(domain){

        fetchSubdomains({getSubdomains,setSubdomains,id:domain,params:`key=${subDomainQuery}`});
    }
  },[domain,subDomainQuery])


  
    

  return (
    <MotionDiv>
  
    <Row>
        <Col md={8}>  
          <Title>Manage Domains</Title>
          {/* <h5>Select Domain To View List Of Area Of Specialty</h5> */}
        </Col>
       
          <Col className='text-end'>
          <SearchField query={domainQuery} setQuery={setDomainQuery} placeholder='Search Domain' />
          </Col>
      
        {/* <Col md={3}>
          <AddButton url='/admin/domains/add-domain' title={'Add Domain'} />
          </Col> */}

    </Row>
          <p>Select Domain To View List Of Area Of Specialty</p>

    <Row className=''>
        {domainLoading ?
            [...Array(5)].map((_, index) => (
                <Col md={2}>
                <Skeleton height={'3rem'} className='mb-2' key={index} />
                </Col>
              ))
        :
        domains?.length >0 ? domains?.slice(0,5).map((item,index)=>(
            <Col className='p-2' md={2}>
            <Card className={`rounded-3 d-flex m-1 flex-row align-items-center justify-content-center p-2 ${domain===item?._id ?'selected-domain':'domain-card'}`} style={{height:"100%",cursor:'pointer'}} onClick={()=>dispatch(setDomain(item?._id))}>
        <MdDashboard className='mx-1'/> <div>{item?.domain_name}</div>
            </Card>
            </Col>
        ))
      :
      <p> No Domains
        </p>}
        <Col className='d-flex align-items-center justify-content-end '>
        <Button
                    onClick={()=>navigate(`/admin/domains/all-domains`)}
                    className='add-btn'
                    variant="transparent"
                  >
                   
                      View/Edit <FaChevronRight />
                   
                  </Button>

        {/* <Link to={'/admin/domains/all-domains'} style={{color:'rgba(0, 0, 155, 1)'}}><u>View List <FaChevronRight/></u></Link> */}

        </Col>
        
    </Row>


    <Row  className=' my-3'>
        <Col md={5}>
         <SearchField query={subDomainQuery} setQuery={setSubDomainQuery} placeholder='Search Area of Specialty'/>
        </Col>
        <Col className=' d-flex align-items-center justify-content-end' >
         {/* <Link style={{color:'rgba(0, 0, 155, 1)'}} to={`/admin/domains/all-specialties`}><u>View All</u> <FaChevronRight /></Link>  */}
         <Button
                    onClick={()=>navigate(`/admin/domains/all-specialties`)}
                    className='add-btn'
                    variant="transparent"
                  >
                   
                      View/Edit <FaChevronRight />
                   
                  </Button>
         <Link  className='ms-2 p-2' to={'/admin/domains/summary'} style={{
          background:'rgba(238, 244, 255, 1)',
          color:'rgba(97, 114, 243, 1)',
          fontWeight: 500,
          
          borderRadius: "6px",
          }}><FaCircleInfo /> View Summary <FaChevronRight /></Link> 
         
        </Col>
    </Row>

<hr/>
    <Row sm={2} xs={1} className=''>
    {subdomainLoading ?
  [...Array(8)].map((_, index) => (
    <Skeleton height={'2.5rem'} className='mb-2' key={index} />
  ))

:
       subdomains?.length >0 ? subdomains?.map((item,index)=>(
            <Col className='py-1'>
<div className="d-flex justify-content-between align-items-center  border rounded p-2 " onClick={()=> {
  dispatch(setSubdomain(item?._id))
  navigate(`/admin/domains/topic-subtopic`)
  
 } } style={{cursor:'pointer'}}>
            <span>{item?.sub_domain_name}</span>
            <FaAngleRight />
          </div>
           </Col>
        ))
        : <p>No Specialty</p>
        
        }
    </Row>
   
  
   </MotionDiv>
  )
}

export default Domains