import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  MessageBox,
  MotionDiv,
  CustomTable,
  ViewButton,
} from "../../components";
import { getError } from "../../utils/error";
import { Row } from "react-bootstrap";
import CountCard from "../../components/layout/CountCard";
import { DeleteButton, ReceiptButton } from "../../components/layout/CustomTable";
import { imgAddr, useDeleteUserMutation, useGetUsersMutation } from "../../features/apiSlice";
import ModalTemplate from "../../components/modals/ModalTemplate";

export default function Users() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [usersCount, setUsersCount] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [query, setQuery] = useState("");

  const curPageHandler = (p) => setCurPage(p);

  const [getUsers, { isLoading }] = useGetUsersMutation();
  const [deleteUser, { isLoading:deleteLoading }] = useDeleteUserMutation();
  const [users, setUsers] = useState([]);
  const [countUsers, setCountUsers] = useState(0);
  const [newUsers, setNewUsers] = useState(0);
const [selectedUser,setSelectedUser] = useState(null)
  const numOfPages = Math.ceil(usersCount / resultPerPage);
  const skip = resultPerPage * (curPage - 1);
  const [showCancelModal,setShowCancelModal] = useState(false);
  const handleShowCancelModal = ()=>setShowCancelModal(true);
const handleHideCancelModal = ()=>setShowCancelModal(false);
  // console.log("no of Page", numOfPages, resultPerPage, usersCount);

  const column = [
    "No.",
    "Name",
    "Email",
    "Area Of Speciality",
    "Subscription Plan",
    "Prof. Name",
    "Expiry Date",
    "Action",
  ];

  useEffect(() => {
    getAllUsers();
  }, [query]);

  const getAllUsers = async () => {
    try {
      const { data } = await getUsers(query);
      setCountUsers(data.userCount);
      setUsers(data.users);
      setNewUsers(data.currentMonthUserCount)
    } catch (error) {
      getError(error);
    }
  };
  const handleDeleteUser = async () => {
    try {
      const data = await deleteUser(selectedUser?._id).unwrap();
       
      toast.success(data?.message);
      getAllUsers();
      handleHideCancelModal();

    } catch (error) {
      getError(error);
      handleHideCancelModal();
    }
  };

  // console.log(users);

  const viewUserDataHandler = (user, name, email) => {
    localStorage.setItem("userDetails", JSON.stringify(user));
    localStorage.setItem("professorName", name);
    localStorage.setItem("professorEmail", email);
    navigate(`/admin/users/view-user`);
  };

  return (
    <MotionDiv>
      {error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <>
          <Row>
            <CountCard color="var(--green-color)" title={"Total Users"} count={countUsers} />
            <CountCard color="var(--purple-color)" title={"Newly Added Users"} count={newUsers} />
          </Row>

          <CustomTable
            loading={isLoading}
            column={column}
            rowNo={resultPerPage}
            rowProps={{ setResultPerPage }}
            paging={numOfPages > 0}
            pageProps={{ numOfPages, curPage }}
            pageHandler={curPageHandler}
            search={true}
            searchProps={{ searchInput, setSearchInput, setQuery }}
            isTitle="true"
            title="User Details"
          >
            {users &&
              users?.map((user, i) => (
                <tr key={user?._id} className="odd">
                  <td className="text-center">{skip + i + 1}</td>
                  <td>
                    {user?.first_name} {user?.last_name}
                  </td>
                  <td>{user?.email}</td>
                  <td>{user?.subdomain?.sub_domain_name}</td>
                  <td
                    style={{
                      color: "#213446",
                      fontWeight: 600,
                    }}
                  >
                    {user?.active_transactions[0]?.amount && "£"}{" "}
                    {user?.active_transactions[0]?.amount || 'N/A'}
                  </td>
                  <td>
                    {
                      user?.professors?.map(prof=>prof?.first_name + ' '+ prof?.last_name).join('|')
                      
                      }
                  </td>
                  <td>{user?.active_transactions[0]?.expiry.split("T")[0] || 'N/A'}</td>
                  <td>
                    {user?.active_transactions?.length>0? 
                      <a
                        href={`${imgAddr+user?.active_transactions[0]?.invoice_url}`}
                      >
                        <ReceiptButton />
                      </a>
                    :
                    <ReceiptButton disabled={true}/>
                    }
                    <ViewButton
                      onClick={() =>
                        viewUserDataHandler(
                          user,
                          user?.professors[0]?.first_name +
                            user?.professors[0]?.last_name,
                          user?.professors[0]?.email
                        )
                      }
                    />
                   <DeleteButton
                      onClick={() =>{
                        setSelectedUser(user)
                       handleShowCancelModal()
                     console.log('deleted')
                      }}
                    />
                  </td>
                </tr>
              ))}
          </CustomTable>
        </>
      )}
      <ModalTemplate
    title={"Are you sure you want to delete this user?"}
    src={'/icons/question-red.png'}
    onDiscard={handleHideCancelModal}
    show={showCancelModal}
    onHide={handleHideCancelModal}
    onConfirm={handleDeleteUser}
    loading={deleteLoading}
    deleteBtn={true}
/>
    </MotionDiv>
  );
}
