import React, { useEffect, useState } from 'react'
import { HiMinusCircle, HiPlusCircle } from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom"
import { MotionDiv } from '../../components';
import { useGetSummaryMutation } from '../../features/apiSlice';
import { getError } from '../../utils/error';
import { setDomain, setSubdomain, setSubtopic, setTopic } from '../../features/optionSlice';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';

// import ModuleContainer from '../../../components/layout/ModuleContainer'

// import { SUB_TOPICS } from '../../../constants/AreaTopic'

const ViewSummaryContainer = ({ children, topic, containerHeader }) => {


    const [isOpen,setIsOpen] = useState(true);



    return (
        <div
            style={{
                borderLeft: '2px solid var(--primary-color)',
                position: 'relative',
                padding: '1rem 1rem',
            }}
            className='mt-2 ms-2'
        >
            <span
                style={{
                    position: 'absolute',
                    top: '-17.5px',
                    left: '-10.5px',
                    cursor: 'pointer',
                    
                }}
            >
              {isOpen?
              
            <HiMinusCircle size={19} style={{ color: 'var(--primary-color)' }} onClick={()=>setIsOpen(!isOpen)} />
        :

                <HiPlusCircle size={19} style={{ color: 'var(--primary-color)'}} onClick={()=>setIsOpen(!isOpen)} />
        }  
            
            </span>
            {containerHeader}

           <div style={{height:`${isOpen?'auto':'0'}`,overflow:'hidden',transition:'all 0.3s',display:`${isOpen?'block':'none'}`}} className='p-1'>{children}</div>

        </div>
    )
}

const ViewSummary = () => {

     const dispatch = useDispatch();
     const navigate = useNavigate();

    const [getSummary,{isLoading}] = useGetSummaryMutation();

    const [summary,setSummary] = useState(null)
    const fetchSummary = async()=>{
        try {
            const data = await getSummary().unwrap();
            console.log(data);
            setSummary(data);

        } catch (error) {
            getError(error)
        }
    }


    useEffect(()=>{
        fetchSummary();
    },[])
    return (
        <MotionDiv>
{isLoading?
<div className='w-100 text-center'>
    
    <Spinner size='lg' className=''/>

    </div>
:


summary?.domains && summary?.domains?.map(( domain,i) => (

            <ViewSummaryContainer
            key={domain?._id}
                containerHeader={<h6
                    style={{
                        position: 'absolute',
                        top: '-12px',
                        left: '1rem',
                        color: 'var(--primary-color)',
                    }}
                >{domain?.domain_name}</h6>}
            >
             {domain?.subdomains && domain?.subdomains?.map((subdomain,i)=>(
                <ViewSummaryContainer
                key={subdomain?.subdomain_id}
                    containerHeader={<h6
                        style={{
                            position: 'absolute',
                            top: '-12px',
                            left: '1rem',
                            // color: 'var(--primary-color)',
                        }}
                    >{subdomain?.subdomain}</h6>}
                >
                    {subdomain?.topics && subdomain?.topics?.map(( topic, index) => (
                        <div className='mb-4'>
                            <ViewSummaryContainer
                                key={topic?.topic_reference}
                                containerHeader={
                                    <div
                                        className='d-flex justify-content-between align-items-start'
                                        style={{
                                            position: 'absolute',
                                            top: '-10px',
                                            left: '1rem',
                                            width: 'calc(100% - 2.5rem)',
                                        }}
                                    >
                                        <h6>{topic?.topic_name}</h6>
                                        
                                            
                                            <p
                                             onClick={()=>{
                                                dispatch(setDomain(domain?._id));
                                                dispatch(setSubdomain(subdomain?.subdomain_id))
                                                dispatch(setTopic(topic?.topic_reference));
                                                dispatch(setSubtopic('all'))
                                                    navigate('/admin/questions')
                                                }}
                                                style={{
                                                    borderRadius: '0.25rem',
                                                    color: 'white',
                                                    backgroundColor: '#6172F3',
                                                    padding: '0.2rem 0.375rem',
                                                    fontSize: '0.75rem',
                                                    cursor:'pointer'
                                                }}
                                            >No. Questions: {topic?.questionsCount}</p>
                                        
                                    </div>
                                }
                            >
                                {topic?.subtopics && topic?.subtopics.map((subtopic, index) => (
                                    <div key={subtopic?._id}>
                                    <hr className='w-100 my-0' />
                                    <div className='w-100 py-2 ps-3 pe-2 d-flex justify-content-between align-items-start'>
                                        <p>{subtopic?.sub_topic_name}</p>
                                        <p
                                        onClick={()=>{
                                            // console.log(summary?.topics[key]?._id)
                                            dispatch(setDomain(domain?._id));
                                            dispatch(setSubdomain(subdomain?.subdomain_id))
                                            dispatch(setTopic(topic?.topic_reference));
                                            dispatch(setSubtopic(subtopic?._id))
                                                navigate('/admin/questions')
                                            }} 
                                            style={{
                                                borderRadius: '0.25rem',
                                                color: '#9E9E9E',
                                                backgroundColor: '#F7F7F7',
                                                padding: '0.2rem 0.375rem',
                                                fontSize: '0.75rem',
                                                fontWeight: 600,
                                                cursor:'pointer'
                                            }}
                                        >No. Questions: {subtopic?.questionsCount}</p>
                                    </div>
                                </div>))}
                            </ViewSummaryContainer>
                        </div>
                    ))}
                </ViewSummaryContainer>

             ))}   
                
            </ViewSummaryContainer>
))  

}
        </MotionDiv>
    )
}

export default ViewSummary

/*
<div
                style={{
                    borderLeft: '2px solid var(--primary-color)',
                    position: 'relative',
                    padding: '2rem 1.5rem',
                }}
            >
                <span
                    style={{
                        position: 'absolute',
                        top: '-15px',
                        left: '-9px',
                        cursor: 'pointer',
                    }}
                ><HiMinusCircle style={{ color: 'var(--primary-color)' }} /></span>
                <h6
                    style={{
                        position: 'absolute',
                        top: '-12px',
                        left: '1rem',
                        color: 'var(--primary-color)',
                    }}
                >Engineering</h6>

                <div
                    style={{
                        borderLeft: '2px solid var(--primary-color)',
                        position: 'relative',
                        padding: '2rem 1rem',
                    }}
                >
                    <span
                        style={{
                            position: 'absolute',
                            top: '-14px',
                            left: '-7px',
                            cursor: 'pointer',
                        }}
                    ><ViewSummaryDropdownIcon /></span>
                    <h6
                        style={{
                            position: 'absolute',
                            top: '-10px',
                            left: '1rem'
                        }}
                    >Mechanical Engineering</h6>

                    {SUB_TOPICS.map(({ topic, subtopics }, index) => (
                        <div
                            key={index}
                            style={{
                                borderLeft: '2px solid var(--primary-color)',
                                position: 'relative',
                                padding: '2rem 1rem',
                                marginBottom: '1rem',
                            }}
                        >
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '-14px',
                                    left: '-7px',
                                    cursor: 'pointer',
                                }}
                            ><ViewSummaryDropdownIcon /></span>
                            <div
                                className='d-flex justify-content-between align-items-start'
                                style={{
                                    position: 'absolute',
                                    top: '-10px',
                                    left: '1rem',
                                    width: 'calc(100% - 2.5rem)'
                                }}
                            >
                                <h6>Topic {index + 1}: {topic}</h6>
                                <p
                                    style={{
                                        borderRadius: '0.25rem',
                                        color: 'white',
                                        backgroundColor: '#6172F3',
                                        padding: '0.2rem 0.375rem',
                                        fontSize: '0.75rem'
                                    }}
                                >No. Questions: 100</p>
                            </div>
                            {subtopics.map((subtopic, index) => (<div>
                                <hr className='w-100 my-0' />
                                <div className='w-100 py-2 ps-3 pe-2 d-flex justify-content-between align-items-start'>
                                    <p>{subtopic}</p>
                                    <p
                                        style={{
                                            borderRadius: '0.25rem',
                                            color: '#9E9E9E',
                                            backgroundColor: '#F7F7F7',
                                            padding: '0.2rem 0.375rem',
                                            fontSize: '0.75rem',
                                            fontWeight: 600
                                        }}
                                    >No. Questions: 25</p>
                                </div>
                            </div>))}
                        </div>
                    ))}
                </div>
            </div>
*/