import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap';

function ReferenceEditor({reference,onChange}) {

    const textareaRef = useRef(null);

  const [height, setHeight] = useState('auto');

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [reference]);
  return (
    <Form.Group className=" w-100" >
    <Form.Control className='border-1 w-100 '
     value={reference} 
     ref={textareaRef}
      onChange={onChange}
        as="textarea"
        style={{height,resize:'none',overflow:'hidden'}}
    // rows={1} 
placeholder="Enter Reference..."
/>
</Form.Group>
  )
}

export default ReferenceEditor