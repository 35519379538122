import React from 'react'

export const CustomCardLabel = ({ label }) => {
    return (
        <p style={{ fontSize: "0.80rem", color: "#475467", fontWeight: 500 }}>{label}</p>
    )
}

export const CustomCardStatisticalValue = ({ value, color, fontWeight }) => {
    return (
        <h1 style={{ fontSize: "1.5rem", color: color ? color : "#101828", fontWeight: fontWeight ? fontWeight : 700 }}>{value}</h1>
    )
}

export const CustomCard = ({ topLeft, topRight,bottom, bottomLeft, bottomRight, width, height, paddingBottom, minWidth }) => {

    const CardStyles = {
        backgroundColor: "#FFF",
        border: "1px solid #EAECF0",
        boxShadow: "0px 0.9233576655387878px 1.8467153310775757px 0px #1018280F",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: "1rem",
        padding: "1rem",
        // overflow:'hidden'
    }

    return (
        <div style={{ ...CardStyles, 
            height: height ? height : "100%", 
            width: width ? width : "100%",
            minWidth: minWidth ? minWidth : "0",
        }}>
            <div className='d-flex justify-content-between align-items-start py-0'>
                <div>{topLeft}</div>
                <div>{topRight}</div>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
                <div>{bottomLeft}</div>
                <div>{bottomRight}</div>
            </div>
            <div>
                {bottom}
            </div>
        </div>
    )
}

export default CustomCard