import React from 'react'

function DateTimeFormater({createdAt,timeReq=true,newLine=false}){
    const date = new Date(createdAt);
  
    const dateFormatter = new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
  
    const timeFormatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  
    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(date);
  
    return (
      <>
        {formattedDate}{newLine?<br/>:''}{timeReq? formattedTime:null}
      </>
    );
  };

export default DateTimeFormater