import React from "react";
import { Modal, Image, Button, Row, Col, Spinner } from "react-bootstrap";

function ModalTemplate({
  show,
  onHide,
  src,
  title,
  size,
  description,
  onDiscard,
  onConfirm,
  loading = false,
  // color,
  buttonConfirmTxt,
  buttonCancelTxt,
  deleteBtn=false,
  children
}) {
  return (
    <Modal show={show} size={size || 'md'} onHide={onHide} centered>
      <Modal.Body className="text-center">
        <Image src={deleteBtn?'/icons/deleteIcon.png': src} style={{maxHeight:'60px'}} className="my-2" />
        <h5 className="my-2 mb-4">{title}</h5>
        <p className="">{description}</p>
        {children}
        <Row className="my-2">
          <Col>
            {onDiscard && (
              <Button
                variant="transparent"
                className="add-btn w-100 m-1"
                onClick={onDiscard}
              >
                {buttonCancelTxt ? "Keep Open" : "Discard"}
              </Button>
            )}
          </Col>
          <Col>
            {onConfirm && (
              <Button
                variant="transparent"
                className={` ${deleteBtn?'del-btn':'blue-btn'} w-100 m-1`}
                disabled={loading}
                onClick={onConfirm}
              >
                {loading ? (
                  <Spinner size="sm" />
                ) : buttonConfirmTxt ? (
                  buttonConfirmTxt
                ) : (
                 deleteBtn?'Delete':"Confirm"
                )}
              </Button>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ModalTemplate;
