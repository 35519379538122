import React, { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';
 import Skeleton from 'react-loading-skeleton';
import Select  from 'react-select';


function FormField({ className,label, type, placeholder,dateMax,max, id, onChange, name, min,value, disabled = false,loading=false, required=true, options,maxLength , as, rows}) {
  
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const getFormattedPastDate = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toISOString().split('T')[0];
  };

  const customStyles = {
    // option: (provided, state) => ({
    //   ...provided,
    //   backgroundColor: state.isFocused ? 'rgba(45, 49, 166, 1)' : 'transparent', 
    //   color: state.isFocused ? '#fff' : '#000', 
    //   '&:hover': {
    //     backgroundColor: 'rgba(45, 49, 166, 1)', 
    //     color:'#fff'
    //   },

    // }),
    dropdownIndicator: (provided,state) => ({
      ...provided,
      padding: '0 0.3rem',
      svg: {
          fill:  "#6172F3",
          transition: 'transform 0.3s ease',
          transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',

      },
  }),
  singleValue: (provided) => ({
      ...provided,
      color:  "#00008B",
  }),
  
  control: (provided) => ({
      ...provided,
      border: '2px solid var(--primary-color)',
      borderRadius:'0.5rem'
  }),
  
  menu: (provided) => ({
      ...provided,
      zIndex: 9999, 
      borderRadius:'0.5rem',
      overflowX:'hidden',
  }),
  option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'var(--primary-color)' : 'white',
      color: state.isSelected ? 'white' : '#00008B',
      
      // fontWeight: state.isSelected ? 700 : 500,
      cursor: 'pointer',
      '&:hover': {
          backgroundColor: '#D1E7FF',
          color:'#000',
      },
  }),
 
  };
  
  return (
    <Form.Group  className={`mb-3 ${className}`}  controlId={id} >
     {label && <Form.Label className=''style={{color:'rgba(52, 64, 84, 1)',fontWeight:500}} >{label}</Form.Label>}

      {
      loading?
      <Skeleton height={'2rem'} />
      :
      type === 'select' ? (
        
        <Select
        className="form-fid "
        name={name}
        value={options.find(option => option.value === value) || null}
        options={options}
        isSearchable
        onChange={(selectedOption) => onChange && onChange({ 
          target: {
            name,
            value: selectedOption ? selectedOption.value : '',
            label: selectedOption ? selectedOption.label : '',  
          } 
        }
        
          )
        
        }
        isDisabled={disabled}
        placeholder={placeholder}
        required={required}
        styles={customStyles}
        // styles={
        //   {con}
        // }
      />
       
      )
  
      :   
      type === 'dropdown'?
      (
      <Form.Select  className="drop-option  "  name={name} value={value} onChange={onChange} disabled={disabled} >
      {options?.map((option, index) => (
        <option key={option?.value} value={option?.value}  className='custom-drop'>
          {option?.label}
        </option>
      ))}
    </Form.Select>
      )
    :
      type === 'password' ?
      (
      <InputGroup className='form-field'>
        <Form.Control
          className={'  border-0'}
          required={required}
          type={showPassword ? 'text' : 'password'}
          aria-label={showPassword ? 'Show password' : 'Hide password'}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
         style={{borderRight:0}}
        />
        <Button variant="transparent" className=' '  onClick={togglePasswordVisibility}>
          {showPassword ? <HiOutlineEye color='rgba(150, 150, 150, 1)' />: <HiOutlineEyeOff color='rgba(150, 150, 150, 1)' /> }
        </Button>
      </InputGroup>
      ):
      type === 'tel' ? (
        <Form.Control
          className={'form-field  '}
          required={required}
          type={type}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={(e) => {
            const inputValue = e.target.value;
            const isValidNumber = /^-?\d*\.?\d*$/.test(inputValue);
      
            if (isValidNumber) {
              onChange({ target: { name, value: inputValue } });
            }
          }}
          pattern="[-]?\d*\.?\d+"
          disabled={disabled}
        />
      )

      :(
        <Form.Control
          className={'form-field '}
          required={required}
          type={type}
          placeholder={placeholder}
          value={value}
          name={name}
          as={as}
          rows={rows}
          onChange={onChange}
          disabled={disabled}
          max={dateMax? type === 'date' ? getFormattedPastDate() : null:max?max:null}
          min={min}
          maxLength={maxLength}
        />
      )
      
    }

    </Form.Group>
  );
}

export default FormField;
